/* Home page Mobile */
@media (max-width: 575.98px) {
  .seat-flow-custom-scroll {
    display: block !important;
  }
  .scanner img {
    width: 50%;
}
  .qr-kotak img {
    height: auto;
  }
  .profile-border{
    padding: 68px 0px 21px 0px !important;
  }
  .ptb-34 {
    padding: 88.5px 0px 34px 0px !important; 
}
  .tablet-view {
    display: block !important;
  }

  .tablet-view .p-button {
    border: none !important;
    background: #dcefff  !important;
    color: #000 !important;
    font-size: 14px !important;
  }
  .tabview-menu {
    display: flex;
    align-items: center;
    padding: 7px 4px;
}
.tablet-view .pi {
  font-size: 24px;
}
.tablet-view .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: none;
  border: none;
  color: #000;
}
.tab-view-menu ul li {
  list-style: none;
  color: #000;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  width: 100%;
}

  .city-name {
    font-size: 13px !important;
    font-family: 'Lato' !important;
  }

  .movietime-cities ul li {
    font-size: 13px !important;
  }

  .city_list {
    left: -4px !important;
    top: 23px !important;
  }

  .movie-time-voucher-pass {
    width: 100% !important;
  }

  .company p {
    width: 100% !important;
  }

  .member-content {
    text-align: center !important;
  }

  .Terms-condition-cinema ul {
    padding-left: 22px !important;
  }

  .icon-tick-cinema::before {
    top: 37px !important;
    left: 17px !important;
    height: 69px !important;
  }

  .cinemas-card-profile-bg {
    margin-bottom: 30px;
  }

  .transaction-box h5 {
    font-size: 19px !important;
  }

  .card-transaction h3 {
    margin-bottom: 20px;
  }

  .cinemas-card-profile {
    right: -142px !important;
  }

  .red-voucher-number p {
    font-size: 14px;
  }

  .subscription h5 {
    font-size: 11px !important;
  }

  .payment-method-movie-time {
    margin-bottom: 30px;
  }

  .movie-times-details h2 {
    font-size: 20px !important;
  }

  .movie-time-tab .p-tabview-nav-container {
    padding: 0px 20px;
  }

  .cinema-details h2 {
    margin-top: 15px;
    margin-bottom: 6px;
  }

  .error-bg {
    height: 100vh !important;
  }
  .error-content p{
    margin-bottom: 16px !important;
  }
  .error-img img {
    width: 86% !important;
}
  .history-kotak-side .p-sidebar-bottom .p-sidebar {
    height: 26rem !important;
    width: 100%;
  }

  .history-kotak-side .p-sidebar-bottom {
    height: 26rem !important;
    width: 100%;
  }

  .bg-point-needed-point {
    width: 100% !important;
    margin-bottom: 15px !important;
    margin: 0px;
  }

  .flat-discount-details p {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .kotakCinema-card img {
    height: 140px !important;
  }

  .flat-discount-details h5 {
    font-size: 12px;
  }

  .expericenece-kotak h2 {
    font-size: 22px !important;
  }

  .expericenece-kotak p {
    font-size: 14px;
  }

  .kotak-newcard img {
    margin-bottom: 20px;
  }

  .get-free-ticket {
    margin-bottom: 20px;
  }

  .kokta-apply {
    text-align: center;
  }

  .kotak-offer-pp {
    margin-bottom: 10px;
    text-align: center;
  }

  .kindly-mobile p {
    font-size: 14px;
    margin-bottom: 0px;
    color: #FFFFFF99 !important;
  }

  .kindly-mobile img {
    padding-right: 5px;
  }

  .voucher-redmeeauto {
    margin-right: 10px !important;
    width: 70% !important;
    flex-shrink: 0 !important;
    margin-bottom: 20px !important;
  }

  .payment-fnb {
    width: 63% !important;
    flex-shrink: 0 !important;
    margin-bottom: 20px !important;
  }

  /* About Us Time linear*/
  .bank-offer-middle {
    align-items: center;
  }

  .bank-offer-details-side h5 {
    font-family: poppins !important;
    font-size: 16px !important;
    color: #000;
  }

  .ourjourney-ptb {
    padding: 45px 0px !important;
  }

  .timeline-container {
    font-family: "Roboto", sans-serif;
    margin: auto;
    padding: 0px 20px;
    display: block;
    position: relative;
  }

  .timeline-container ul.tl {
    margin: 20px 0;
    padding: 0;
    display: inline-block;

  }

  .timeline-container ul.tl li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    border-left: 1px solid #000;
    padding: 0 0 40px 30px;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .joureny-inception p {
    margin-bottom: 0px;
  }

  .timeline-container ul.tl li.dashed {
    border-left: 1px dashed #000;
  }

  .timeline-container ul.tl li:last-child {
    border-left: 0;
  }

  .timeline-container ul.tl li .item-icon {
    position: absolute;
    left: -8px;
    top: -5px;
    content: " ";
    border-radius: 50%;
    background: #FFCB05;
    height: 16px;
    width: 16px;
    border: 2px solid #000;
  }

  .timeline-container ul.tl li:hover::before {
    border-color: #258CC7;
    transition: all 1000ms ease-in-out;
  }

  ul.tl li .item-text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  ul.tl li .item-title {
    font-size: 16px;
    font-weight: 700;
  }

  ul.tl li .item-detail {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }

  ul.tl li .item-timestamp {
    color: #8D8D8D;
    font-size: 12px;
    text-align: right;
    padding-left: 20px;
  }

  /* About us Time linear */
  /* food ticket */
  .tick-icon img {
    margin-top: 15px !important;
  }

  /* earlyaccess */
  .earlyaccess-show {
    padding: 0px 10px 15px 10px !important;
  }

  .vote-btn {
    padding: 5px 128px !important;
  }

  .voted-box {
    width: 94% !important;
  }

  .final-vote {
    height: 100vh !important;
  }

  .Poster-movies {
    top: 18% !important;
    left: 37% !important;
  }

  .star-vote {
    margin-top: 49% !important;
  }

  .movie-poster-vote {
    width: 29% !important;
  }

  .Vote-verfiy-user {
    padding: 0px 20px !important;
  }

  .voter-now {
    padding: 0px 0px !important;
  }

  .fixed-mobile-voted {
    position: fixed;
    bottom: 0px;
    width: 95%;
    margin-bottom: 10px;
    background: #fff;
  }

  .search-icon-grey-vote {
    top: 6px !important;
    left: 36px !important;
  }

  .location-deduction {
    right: 43px !important;
    top: 8px !important;
  }

  .search-icons-vote {
    padding: 0px 28px;
  }

  .City-list-vote ul {
    padding: 0px 18px !important;
  }

  .voted-box-winner {
    width: 94% !important;
  }

  .Poster-movies-winner {
    top: -8% !important;
    left: 33% !important;
  }

  .Movies-vote.Closed {
    width: 100% !important;
  }
  .bulk-from{
    box-shadow: none !important;
    padding: 7px !important;
  }
  .about-tab-view {
    padding: 0px 17px;
}
.cinema-movie-poster img{
  width: 100%;
}
  .voting-closed {
    width: 100% !important;
  }

  /* earlyaccess */
  .faq {
    // margin-top: 14%;
    margin-top: 0%;
  }

  .cancel-icon {
    position: absolute;
    top: 29%;
    right: 20px;
  }

  .mobile-mybooking-box {
    position: relative;
  }

  .single-image-format img {
    width: 100% !important;
  }

  .jio-screen-view {
    /* width: 88% !important; */
    margin-right: 10px;
  }

  .mobile-fomat-spaces {
    margin-right: 10px;
  }

  .p-datepicker {
    z-index: 99999 !important;
  }

  .p-dropdown-panel {
    z-index: 99999 !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #000 !important;
  }

  .resend-otp h6 {
    text-align: center;
  }

  .all-summary {
    margin-bottom: 40%;
  }

  .cinema-movie-times .p-accordion .p-accordion-tab {
    border-radius: 0px !important;
    padding-bottom: 8px;
  }

  .offer-box {
    padding-bottom: 100px;
  }

  .Final-ticket-terms {
    margin-bottom: 100px !important;
  }

  .veg-price p {
    text-align: right;
  }

  .p-component-overlay {
    z-index: 99999 !important;
  }

  .movies-name-titile-mobile {
    width: 100%;
    overflow: hidden;
  }

  .movie-info-showed-mobile {
    font-size: 18px !important;
    font-weight: 700 !important;
    margin: 0;
    overflow: hidden;
    animation: marquee 10s linear infinite;
  }

  @keyframes marquee {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    10% {
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }

  .advertise-photo {
    margin-top: 14%;
  }

  .faq-question {
    width: 90%;
  }

  .web-switch {
    z-index: 9999 !important;
  }

  .select-time.popular-food {
    width: 100% !important;
  }

  .cinema-listed-locat {
    width: 85%;
  }

  .heart-direction {
    width: 15%;
  }

  .m-top-12 {
    margin-top: 21%;
  }

  .mfeedback-top {
    margin-top: 3%;
  }

  .bulk-from {
    width: 100% !important;
  }

  .drawer-complete-mobile h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 100%;
  }

  .drawer-complete-mobile {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .text-feilds-poup {
    padding: 4.2px 17px 3.5px 17px !important;
  }

  .mobile-gift-voucher.my-3.border.rounded.mobile-booking-history {
    margin-top: 0px;
  }

  .booking-detail-info {
    padding-left: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
  }

  .mobile-ticket-details.mx-2 h3 {
    padding-left: 0px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .mobile-movie-info.d-flex.bg-final-mobile {
    padding: 14px;
    background-color: #f9f9f9;
    border-radius: 20px 20px 0px 0px;
    margin-bottom: 12px;
    ;
  }

  .back-to-booking .download-ticket {

    margin-bottom: 10px;

  }

  .back-to-booking button.sc-crHlpi.lfwRjs.download-ticket {
    width: 60%;
  }

  .bulk-contact.bulk-mobile {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .bulk-contact.bulk-mobile .bulk-submit {
    width: 100%;
    margin-top: 15px;
  }

  .mobile-search-cinemas-show .movies-search-details {
    display: flex !important;

  }

  .incre-decre svg {
    /* color: #000; */
  }

  .search-movies-name {
    width: 25%;
  }

  .mobile-search-cinemas-show .search-movies-name img {
    height: auto !important;
  }

  .movies-search-details {
    display: flex;
    width: 70%;
    flex-direction: column;
  }

  .mobile-search-cinemas-show {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .mobile-setalert-icon-cinema {
    text-align: center;
    font-family: 'Poppins';
    color: #000;
  }

  .mobile-setalert-icon-cinema p {
    font-size: 14px;
    padding: 0px 30px;
  }

  /* .mobile-gift-voucher.my-3.border.rounded {
      padding-bottom: 0px;
      margin: 0px 20px;
      margin-top: 64px !important;
  } */
  .d-flex.justify-content-between.py-2.px-1.bg-muted {
    background-color: #f1f1f1;
    padding: 14px 6px !important;
  }

  .d-flex.justify-content-between.py-2.px-1.date-time-order {
    padding: 14px 6px;
  }

  /* .mobile-gift-voucher.my-3.border.rounded img {
      margin-top: 33px;
  } */
  .d-flex.justify-content-between.py-2.px-1.bg-muted {
    background-color: #f6f6f6;
    font-family: 'Poppins';
    font-size: 13px;

  }

  .careers-content h5 {
    margin-top: 50px;
  }

  .registered-address {
    margin-bottom: 0px;
  }

  .careers ul {
    display: flex;
  }

  .mobile-experiences-formats .formsts-show-mobile p {
    text-transform: uppercase;
  }

  .select-date.align-self-center.arrow-btn-right .p-dropdown-trigger {
    position: relative;
    left: -6px;
  }

  .home-colse {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
  }

  .applied-info p {
    margin-bottom: 0px;
  }

  .security_code {
    padding: 0px 0px !important;
  }



  /* .select-date.align-self-center.arrow-btn-right .p-dropdown-trigger {
    position: relative;
    left: -6px;
  } */
  .jio-without {
    margin-right: 10px;
  }

  /* also playing */
  .Also-playing h5 {
    font-size: 14px;
    margin-bottom: 20px;
    font-family: 'Lato';
  }

  .All-poster-show-slide-mobile {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    margin-bottom: 80px;
  }

  .lastest-movies-poster-mobile {
    width: 30%;
    margin: 0px 0px;
    flex: 0 0 auto;
  }

  .lastest-movies-poster-mobile img {
    width: 88%;
    border-radius: 8px;
  }

  .All-poster-show-slide-mobile::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  /* also playing */


  /* cinema details page */
  .imax-experinces {
    height: 100% !important;
    padding: 0px 0px 0px 0px !important;
  }

  .imax-logo {
    padding: 0px 0px 0px 0px !important;
  }

  .imax-logo h1 {
    color: #fff;
    font-size: 16px !important;
  }

  .cinema-banner-imax h2 {
    font-weight: 500 !important;
  }

  .cinema-banner-imax {
    margin-top: 24px;
  }

  .search-mobile-cinema-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fliter-search {
    width: 94%;
  }

  .cross-mobile-cinema-details {
    width: 6%;
    text-align: right;
  }

  /* cinema details page */
  /* cvp popup css */
  .quantity-cv.now-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;

  }

  .gift-info-container {
    padding-left: 20px !important;
  }

  .gift-container {
    padding-left: 0px !important;
  }

  .gift-show-left {
    padding-left: 20px;
  }

  .cards-gift {
    padding-left: 20px;
    margin-bottom: 150px;
  }

  .gift-details.mobile-mt-40 {
    padding-left: 20px;
  }

  .Mobile-btn-views {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  .gift-details-btn {
    margin-right: 40px;
  }

  .cvp-ticket {

    border-bottom: 1px solid #ccc;
  }

  .drop-quanity {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .custom-dropdown-cvp {
    right: 5px;
    position: absolute;
    top: 7px !important;

  }

  .cvp-quanity {
    align-items: start;
    flex-direction: column;
  }

  .cvp-ticket {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 15px;
    width: 100%;
  }

  .drop-quanity::before {
    display: none !important;
  }

  /* cvp popup css */


  body {
    user-select: none !important;
  }

  .select-date {
    width: 28% !important;
  }

  .seat-layout {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    height: auto !important;
    padding: 0px !important;
  }

  .seat-layout::-webkit-scrollbar {
    width: 0px !important;
  }

  .all-seats {
    overflow-x: scroll !important;
  }

  /* Track */
  .seat-layout::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .show-times h5 {
    font-size: 12px !important;
  }

  /* Handle */
  .seat-layout::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .seat-layout::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  .launch-movie-cinema {
    background: none;
  }
  .cinema-movie-poster img{
    margin-bottom: 12px;
  }
  .date-time {
    margin-top: 21%;
  }

  .date-fix-moviesession {
    top: 4.2rem !important;
  }
  .date-fix-moviesession.cinema-session-date {
    top: 2.8rem !important;
}
  .payment-header-item-movie {
    display: flex;
    justify-content: space-between;
  }

  .movie-name-mobile-show {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
  }

  .circle-tab {
    width: 12px;
    height: 12px;
    background-color: #F00;
    border: 1.5px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: 11px;
    left: 29px;
    display: inherit;
  }

  .movies-arrow-fix-mobile {
    margin-right: 15px;
  }

  .cinema-movie-time {
    margin-top: 84px !important;
  }

  .cinema-movie-time .p-accordion .p-accordion-content {
    border-radius: 0px !important;
  }

  .cinema-movie-time .p-accordion .p-accordion-tab {
    border-radius: 0px !important;
  }

  /* seathover remove */
  .seat-current-cinema:hover {
    background-color: transparent !important;
    border: 1px solid #7A7A7A !important;
    cursor: pointer;
  }

  .seat-current-cinema:hover {
    background-color: transparent !important;
    border: 1px solid #7A7A7A !important;
    cursor: pointer;
    color: #000 !important;
    transform: translateY(0px) !important;
  }

  .select-date .p-dropdown .p-dropdown-trigger {
    width: initial !important;
  }

  .seat-current-cinema {
    transform: inherit !important;
    transition: inherit !important;
  }

  .seat-selected-cinema:hover {
    transform: inherit !important;
    transition: inherit !important;
  }

  .seat-selected-cinema {
    transform: inherit !important;
    transition: inherit !important
  }

  .seat-selected-cinema:hover {
    transform: inherit !important;
    transition: inherit !important
  }

  /* seat hover remove */

  /* profile mmy booking */
  /* .now-showing-filter-cinema .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      padding: 15px 67px !important;
  } */
  .about-tab-view .now-showing-filter-cinema .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px !important;
  }

  .privacy-policy-space h2 {
    font-size: 20px;
    margin-top: 10px;
  }

  .privacy-policy-space h3 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }

  .privacy-policy-space h4 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }

  /* profilr my booking */

  .customies-coupon {
    margin-bottom: 30px !important;
  }

  .enter-amount {
    padding: 0px !important;
    background: none !important;
    border: none !important;
  }

  .gifting {
    background: #F1F1F1 !important;
  }

  .coupon-photo-summary img {
    margin: 18px 0px 18px 0px;
  }

  .summary-mobile-bg {
    background: #F1F1F1 !important;
    padding: 18px !important;
  }

  .Summary-sub-total {
    border: 1px solid #DDD !important;
    border-radius: 8px !important;
    padding: 18px !important;
  }

  .coupon-photo {
    width: 39% !important;
    margin-bottom: 0px !important;
    position: relative;
  }

  .shoow-coupon {
    background: none !important;
    border: none !important;
    padding: 0px !important;
  }

  .coupons-view-all {
    padding: 0px !important;
  }

  .coupon-grand {
    padding: 20px 32px 20px 20px !important;
    background: #F9F9F9;
    /* margin-bottom: 10px; */
  }

  .gift-contents {
    margin-top: 20px !important;
    border-radius: 8px !important;
  }

  .Mobile-topaid {
    font-weight: 600;
    font-size: 14px;
  }

  .gift-voucher img {
    border-radius: 8px;
    margin-bottom: 20px !important;
  }

  .city-dropdown {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }

  .mobile-mt-50 {
    margin-top: 33% !important;
  }

  .mobile-show-cities-fix {
    margin-top: 30% !important;
    margin: 0 auto;
  }

  .food-popup {
    padding: 10px 10px 10px 10px !important;
    flex-wrap: wrap;
  }

  .food-all {
    width: 60%;
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }

  .foods-details {
    width: 100% !important;
    display: flex;
    /* align-items: center; */
    align-items: flex-end;
    justify-content: space-between;
  }

  .mobile-food-boxed-view {
    width: 58%;
  }

  .add-to-cart-popup {
    padding: 8px 26px !important;
  }

  .foods-details h5 {
    margin-bottom: 17px !important;
  }

  .cities-fixed-mobike {
    position: fixed;
    width: 100%;
    z-index: 99999;
    background: #fff;
    margin: 0 auto;
    left: 0px;
    padding: 20px 20px 0px 20px;
    top: 0px;
  }

  .verfiy-paytm {
    width: 83% !important;
  }

  .ticke-cancel-modal {
    width: 93% !important;
  }

  .terms-condition-payment p {
    padding-top: 0px;
    margin-left: 8px;
  }

  .paywithdebit {
    padding: 0px !important;
    border-bottom: none !important;
  }

  .payment-debitcard {
    padding: 24px 14px !important;
  }

  .paywithdebit {
    border-bottom: none;
  }

  .payment-form {
    border: none !important;
    border-radius: 0px !important;
  }

  .nothing-found img {
    width: 64%;
    margin: 0 auto;
    text-align: center;
    display: flex;
  }

  .nothing-found h6 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
  }

  .foods-layout {
    padding: 16px 10px 20px !important;
    height: 100vh !important;
    padding-bottom: 57% !important;
  }

  .mobile-pay-button-box {
    position: fixed !important;
    bottom: 0;
    background: #ffff;
    width: 100%;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.03);
  }

  .order-snack {
    margin: 0px 0px 0px 23px !important;
  }

  .add-to-cart {
    padding: 6px 25px !important;
    font-size: 14px !important;
  }

  .ticket-value {
    margin-bottom: 0px !important;
  }

  .mobile-banner-content {
    width: 68%;
  }

  .mobile-alert-proceed-btn {
    width: 100% !important;
    text-align: center;
  }

  .cross {
    margin-bottom: 0px !important;
    position: absolute;
    right: 23px;
  }

  .alert-btn {
    text-align: right;
    margin-top: 3px;
  }

  .mobile-setalert-icon-cinema {
    text-align: center;
    font-family: poppins;
    color: #000;
    font-weight: 500;
  }

  .new-logo {
    max-width: 100%;
    height: 30px;
  }

  .popup_rate {
    width: 87% !important;
  }

  .ticket-movie {
    width: 30%;
  }

  .mobileserach-cross .colse-btn {
    position: absolute;
    top: 6%;
    right: 9px;
    z-index: 999;
  }

  .mobileserach-cross .colse-btn i.pi.pi-times {
    font-size: 20px;
    font-weight: 600;
  }

  .search-main-box .p-inputtext {
    width: 94%;
  }

  /* session box */
  .time-select {
    height: 53px !important;
    width: 100px !important;
  }

  .other-cities-list {
    padding: 36px 0px 10px 64px !important;
  }

  .other-cities-list ul li {
    font-size: 13px;
  }

  .search-current {
    width: 100% !important;
  }

  /* session box */
  /* default css */
  .banner-Mobile {
    display: none;
  }

  .phn-18 {
    padding-top: 20px;
    padding-bottom: 70px;
  }

  .phn-cinemas {
    padding-top: 50px;
    padding-bottom: 60px;
    background: #DCEFFF;
  }

  /* default css */
  .mobile .p-calendar {
    width: 100%;
  }

  .mobile .p-calendar .p-inputtext {
    width: 100%;
    border-right: none !important;
    border-radius: 0px 0px;
  }

  .careers-photo {
    margin-bottom: 50px;
  }

  .seat-terms-condition-flow {
    width: 92% !important;
  }

  .typhor-content {
    width: 100%;
  }

  .typhor-content h6 {
    width: 100%;
  }

  .brands-logo img {
    width: 90%;
  }

  .company-fix {
    position: sticky;
    top: 3rem;
    z-index: 9;
    background: #fff;
    width: 100%;
  }

  .play-trailers {
    height: 42% !important;
  }

  .reserve-phn {
    display: flex;
    flex-direction: column-reverse;
  }

  .company-photo.align-self-center {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .about-modal-teams-info {
    background: #fff;
    width: 94vw;
    height: 57vh;
    padding: 14px;
  }

  .company p {
    width: 100%;
  }

  .menu-divider {
    width: 86% !important;
    margin: 0 auto !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .more-show-drawer ul li {
    list-style: none;
  }

  /* home page */
  .show-mobile-view h6 {
    margin-bottom: 0px;
    color: #045292;
  }

  /* home page */


  /* more drawer */
  .more-show-drawer {
    padding: 0px 20px;
    height: 28rem;
    border-radius: 8px;
  }

  /* more drawer */
  .nowshowing-poster-show .p-card-header img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    border-radius: 4px 4px 0px 0px !important;
  }

  .book-tickets-btn {
    padding: 9px 12px !important;
    font-size: 11px !important;
  }

  /* search */
  .search-main-box .p-input-icon-left {
    display: inline-grid;
    width: 100%;
    padding: 16px 24px;
  }

  .search-main-box .p-inputtext {
    border-radius: 30px !important;
    font-family: 'Poppins' !important;
    font-size: 14px !important;
  }

  .search-icon-main-nav {
    top: 39px !important;
    left: 37px !important;
    font-family: 'Poppins' !important;
    position: absolute;
  }

  .search-mobile-mic {
    display: block;
    position: absolute;
    top: 32px;
    right: 38px;
  }

  .search-dropdown .p-tabview-nav-container {
    padding-bottom: 0px !important;
  }

  .search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px 54px 10px 54px !important;
  }

  .search-dropdown .p-tabview-nav-container {
    width: 96% !important;
    margin: 0 auto !important;
  }

  /* .search-dropdown .p-tabview .p-tabview-panels {
      padding: 32px 24px !important;
    } */

  /* search */
  .show-mobile-view {
    display: block;
  }

  .show-in-desktop-view {
    display: none !important;
  }

  .show-in-mobile-view {
    display: block !important;
  }

  .show-desktop-view {
    display: none !important;
  }

  .desktop-view-M {
    display: none !important;
  }

  .desktop-view-banner-m {
    display: none !important;
  }

  .quick-book-m {
    display: none;
  }

  .now-showing-M {
    display: block;
  }

  .now-showing-only-desktop {
    display: block;
  }

  .experience-M {
    display: none;
  }

  .trailer-m {
    display: none;
  }

  .offer-m {
    display: none;
  }

  .footer-m {
    display: none;
  }

  .coming-soon-desktop {
    display: none;
  }

  .middle {
    display: none;
  }

  .desktop-login-icon {
    display: none;
  }

  .mobile-login-icon {
    display: block;
  }

  .filter-mobile {
    display: block;
    /* background: #234b9e; */
    position: fixed;
    bottom: 57px;
    right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.20);
  }

  .view-experineces-mobile {
    display: block;
  }

  .footer-tab-mobile {
    // display: block !important;
  }

  .play-mobile {
    display: block;
    position: absolute;
    right: 7px;
    top: 56%;
  }

  .offer-desktop {
    display: none;
  }

  .container {
    padding: 0px 20px;
  }

  .now-movie {
    grid-gap: 20px;
  }

  .now-movies {
    width: 46% !important;
    position: relative !important;
  }

  .p-dialog .p-dialog-content {
    padding: 0px !important;
  }

  /* banner trailer start */
  .tariler-show {
    width: 95% !important;
    height: 95% !important;
    margin: 0 auto !important;
  }

  /* banner trailer end */
  /* mobile header */
  .head-mobile-view-m {
    display: block;
  }

  .movie-accordians {
    margin-top: 84px;
  }

  .phone-header {
    padding: 10px 15px 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    border-bottom: 2px solid #CCC;
    background: #DCEFFF;
  }

  .side-icon {
    display: flex;
    align-items: center;
  }

  .order-content {
    justify-content: flex-start !important;
  }

  .scanner {
    margin-left: -10px !important;
    text-align: right;
  }

  .user-name h6 {
    font-size: 12px;
    margin-bottom: -2px;
    font-weight: 600;
    color: #045292;
    font-family: 'Lato';
  }

  .city-dropdown p {
    font-size: 12px !important;
    margin-bottom: 20px;
    text-align: left !important;
  }

  .down-arrow img {
    width: 12% !important;
    top: 0;
  }

  .search-icon-m img {
    width: 24px;
    margin-left: 13px;
  }

  /* mobile header */
  /* mobile banner */
  .control-dots {
    display: none;
  }

  div#trailers {
    margin-bottom: 20px !important;
  }

  .mobile-banner {
    display: block !important;
  }

  .play-icon-m img {
    width: 32px !important;
  }

  .mobile-new {
    display: block !important;
    font-size: 12px;
  }

  /* footer app view */
  .app-view-footer {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px 20px 0px 20px;
    justify-content: space-between;
    z-index: 999;
  }

  .home-icon p {
    font-size: 12px;
    margin: 0;
  }

  .icon-img {
    text-align: center;
  }

  .icon-img img {
    width: 20px;
  }

  .box-slot-moviesession {
    width: 110px !important;
    /* height: 75px !important; */
  }

  .play-icon-m {
    position: absolute;
    right: 15px;
    top: 160px;
  }

  .movie-name-m {
    display: block;
  }

  .movie-name-m {
    padding: 15px 20px;
    top: 127px;
    width: 100%;
  }

  .vip-ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 9px 11px 9px;
    text-align: left;
    background: #ffff;
    margin-top: -72px;
    /*z-index: 9999 !important;
      */
    border-radius: 5px 5px 0px 0px;
    position: sticky;
    border-radius: 0px !important;

  }

  .ticket-movie .btn-gap {
    margin-bottom: 2px !important;
  }

  .mobile-banner-content h6 {
    font-size: 12px;
    color: #808190;
    font-family: "Poppins";
    margin-bottom: 1px;
  }

  .mobile-banner-content h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: -4px;
  }

  .languages-now {
    font-size: 10px;
  }

  .movie-action-bread ul li {
    font-size: 10px !important;
  }

  .movie-action-bread {
    line-height: 15px;
  }

  .ptb-48 {
    padding: 15px 0px 45px 0px;
  }

  .mobile-banner-content ul {
    display: inline-block;
    line-height: 5px;
  }

  .mobile-banner-content ul li {
    list-style: none;
    text-transform: uppercase;
    float: left;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: #808190;
  }

  .mobile-banner-content p {
    font-size: 11px;
    color: #808190;
    margin: 0;
  }

  .book-m {
    border: none;
    padding: 7px 19px;
    font-size: 12px;
    border-radius: 5px;
    background: #234b9e;
    color: #fff;
    font-family: "Poppins";
  }

  .mobile-new {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9;
  }

  .mobile-new h6 {
    background: #234b9e;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 11px;
    margin: 0;
  }

  .icon-mobile-movie {
    overflow: hidden;
    margin-bottom: 5px;
  }

  .view-experineces-mobile {
    width: 100%;
  }

  .select-date.align-self-center.arrow-btn-right .p-dropdown-trigger {
    position: relative;
    left: -6px;
  }

  .applied-info p {
    margin-bottom: 0px;
  }

  /* .select-date.align-self-center.arrow-btn-right .p-dropdown-trigger {
      position: relative;
      left: -6px;
    } */


  .view-experineces-mobile::-webkit-scrollbar-thumb {
    width: 10px;
    background: #000000;
  }

  .view-experiences-box {
    display: -webkit-inline-box;
    overflow: scroll;
    padding: 0px;
    width: 100%;
  }

  .view-experiences-box::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    background: transparent !important;
  }

  .single-data-show {
    width: 100%;
    border: 1px solid #e8e8e8;
    /* margin-left: 7px; */
    padding: 4px 7px;
    border-radius: 6px;
    box-shadow: 0px 1px 0px #e8e8e8;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dual-data-show {
    width: 50%;
    /* border: 1px solid #e8e8e8;
      margin-left: 7px;
      padding: 4px 7px;
      border-radius: 6px;
      box-shadow: 0px 1px 0px #e8e8e8;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center; */
  }

  .mobile-imax {
    border: 1px solid #e8e8e8;
    margin-left: 7px;
    padding: 4px 7px;
    border-radius: 6px;
    box-shadow: 0px 1px 0px #e8e8e8;
    width: 20%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-imax:first-child {
    margin-left: 0px;
  }

  /* movie session mobile */
  .movie-show-desktop-view {
    display: none !important;
  }

  .desktop-view-filter {
    display: none;
  }

  .dates-active {
    padding: 10px 20.5px !important;
  }

  .dates-inactive {
    padding: 11px 20.5px !important;
  }

  .show-spaces {
    margin-bottom: 15.5px !important;
    padding-left: 6px;
    padding-right: 3px;
  }

  .eng {
    bottom: 43px !important;
  }

  /* movie session mobile */
  /* login-flow */
  .email-last {
    /* margin-bottom: 30px; */
    margin-bottom: 0px;
  }

  .register-mobile {
    width: 100% !important;
    height: 100%;
    max-height: 100% !important;
  }

  .otpfeild {
    width: 47px;
    height: 47px;
  }

  .otp-feild input {
    margin-right: 17px !important;
    width: 42px !important;
    height: 42px !important;
    margin-bottom: 30px !important;
    margin-left: 0px !important;
  }

  .number-desktop {
    display: none;
  }

  .mobile-login-M {
    text-align: left !important;
    padding: 0px 30px;
    /* overflow-y: scroll; */
    height: 100vh;
  }

  .mobile-login-M img {
    width: 31% !important;
    margin-bottom: 40px !important;
    padding-top: 76px;
  }

  .desktop-view-login-head {
    display: none;
  }

  .Mobile-view-login-head {
    display: block;
  }

  .Mobile-view-login-head {
    font-size: 30px !important;
    margin-bottom: 20px !important;
  }

  .desktop-view-login-content {
    display: none;
  }

  .Mobile-view-login-content {
    display: block;
  }

  .Mobile-view-login-content {
    font-size: 14px !important;
    color: #908e8e !important;
    margin-bottom: 40px !important;
  }

  .mobile .p-inputtext {
    height: 72px !important;
    border-radius: 8px;
    padding-bottom: 0px;
    color: #000 !important;
  }

  .mobile .p-inputtextarea {
    height: 72px !important;
    border-radius: 8px;
    padding-bottom: 0px;
    padding-top: 26px;
    color: #000 !important;
  }

  .nearby-therater {
    border: 1px solid #ccc;
  }

  .save-btn-edits {
    width: 100%;
    margin: 15px 0px;
  }

  .mobile-save-btn-edit {
    width: 100%;
  }

  .cinema-search .p-inputtext {
    padding: 0px 7px !important;
  }

  .cinema-search .p-input-icon-right>i:last-of-type,
  .p-input-icon-right>svg:last-of-type,
  .p-input-icon-right>.p-input-suffix {
    padding-left: 12px !important;
  }

  .organisation-feild .p-float-label label {
    font-weight: 600 !important;
    font-family: 'Lato' !important;
    color: #908e8e !important;
    font-size: 14px !important;
  }

  .mobile .p-float-label label {
    font-size: 14px !important;
    color: #908e8e !important;
    font-weight: 600;
    font-family: 'Lato' !important;
  }

  .mobile .phone-icon {
    bottom: 26px !important;
  }

  .desktop-cross {
    display: none;
  }

  .register-mobile {
    border-radius: 0px !important;
  }

  .register-btn {
    text-align: center !important;
    position: fixed !important;
    bottom: 0px !important;

  }

  .btn-proceeds {
    position: fixed;
    bottom: 30px;
    left: 0px;
    margin: 0px 28px !important;
    width: 86% !important;
  }

  .desktop-view-mobile-comingsoon {
    display: flex !important;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .now-movie .p-card .p-card-title {
    height: 15px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .now-movies .p-card .p-card-footer {
    padding: 0px !important;
  }

  .notification-mobile {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .notification-mobile {
    display: block;
  }

  .notification-mobile img {
    width: 30px;
  }

  /* login-flow */
  /* city mobile */
  .custom-sidebar {
    width: 100% !important;
  }

  .select-city h4 {
    text-align: center !important;
  }

  .select-city {
    width: 100%;
  }

  .search-current .p-inputtext {
    border-radius: 0px 30px 30px 0px !important;
  }

  .search-current .p-inputgroup-addon {
    border-radius: 30px 0px 0px 30px !important;
  }

  /* city mobile */
  /* moviesession mobile */
  .calnder-current ul {
    display: flex;
  }

  .calnder-current {
    width: 100%;
    overflow-x: scroll;
  }

  .show-details-icon {
    margin-left: 0px !important;
    display: initial !important;
    /* margin-top: 10px; */
  }

  .accesss img {
    margin-right: 7px;
  }

  .movie-session-box-movies {
    display: inherit !important;
  }

  /* moviesession mobile */
  /* showtime mobile */
  .desktop-showtime {
    display: none;
  }

  .movie-name h4 {
    font-size: 11px !important;
  }

  .bg-booking {
    background: #fff !important;
  }

  .cinema-movie-times {
    margin-top: 20px;
  }

  /* movies showtime */
  .cinema-movie-times .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0px !important;
  }

  .icon-imax img {
    width: 10% !important;
  }

  /* movies showtime */
  /* showtime mobile */
  /* mobile filter */
  .filter-head {
    border-bottom: 1px solid #ede8e9;
  }

  .mobile-filter-app {
    height: auto !important;
    background: #DCEFFF;
  }

  .mobile-filter-app .p-sidebar-content {
    padding: 0px;
  }

  .filter-head {
    border-bottom: 1px solid #ede8e9;
  }

  .languages-filter {
    padding: 5px 24px;
  }

  .languages-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
  }

  .languages-perfer ul li {
    margin-right: 8px;
    list-style: none;
    float: left;
    border: 1px solid #ede8e9;
    background: #f9f9f9;
    border-radius: 30px;
    margin-bottom: 8px;
    padding: 7px 20px;
    cursor: pointer;
    font-size: 13px;
    color: #000;
    font-weight: 500;
  }

  .active-languages {
    background: #234b9e !important;
    color: #fff;
  }

  .languages-show h2 {
    font-size: 16px;
    color: #000;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .apply-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    padding: 25px 0px 25px 0px;
    /* position: fixed; */
    width: 100%;
    bottom: 0;
  }

  .reset-btn {
    border: none;
    width: 158px;
    height: 50px;
    border-radius: 10px;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    color: #000;
    background: #ede8e9;
    margin-right: 11px;
  }

  .apply-filters {
    border: none;
    width: 158px;
    height: 50px;
    border-radius: 10px;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 700;
    color: #000;
    background: #234b9e;
    color: #fff;
  }

  .filter-head h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
  }

  /* mobile filter */
  /* seat layout desktop */
  .desktop-seat-bread {
    display: none !important;
  }

  .select-time {
    width: 76% !important;
  }

  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 17px 17px;
  }

  .seat-views {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .screen-viewa {
    margin-top: 0 !important;
  }

  .screen img {
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .desktop-seat-available {
    display: none;
  }

  .screen-mobileput h6 {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-align: center;
    background: #ffffff !important;
    width: 100%;
    margin: 0 auto;
    text-transform: uppercase;
    display: block;
    padding: 0px 4px;
  }

  .all-seats {
    padding-bottom: 50% !important;
  }

  .mobile-time-show p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 18px;
  }

  .quantity-cv h6 {
    font-family: 'Poppins';
    font-size: 14px;
    margin-right: 10px;
  }

  .clock {
    margin-right: 3px;
  }

  .passport-voucher {
    border: 0px !important;
  }

  .seat-number {
    border: none;
    /* width: 10%; */
    border-radius: 10px;
    padding: 14px 0px;
  }

  .seat-number-show {
    width: 100%;
    border: none;
    background: #d3d3d3;
    border-radius: 10px;
    padding: 14px 0px;
    font-weight: 600;
    color: #000;
  }

  .select-seat-mobileview .seats-info {
    margin-bottom: 10px;
  }

  .select-seat-mobileview {
    display: block !important;
    padding: 18px 18px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 100%;
    left: 0px;
  }

  .screen {
    position: relative;
  }

  .screen-mobileput {
    position: absolute;
    top: 7px;
  }

  /* seat layout desktop */
  /* statics page */
  .advertise-photo img {
    margin-bottom: 30px;
  }

  .bulk-from {
    padding-bottom: 50px;
  }

  .advertise-label {
    font-weight: 400 !important;
  }

  .faq-question h4 {
    font-size: 13px;
  }

  .maps p {
    margin-bottom: 30px;
  }

  .careers-content p {
    margin-bottom: 30px;
  }

  .register-address h3 {
    margin-bottom: 15px;
  }

  .careers ul li {
    margin-right: 20px;
    margin-bottom: 30px;
    float: none;
  }

  .careers ul li {
    width: 10%;
  }


  .careers-sapces {
    padding-left: 7px;
    padding-right: 7px;
  }

  .careers-type {
    margin-bottom: 20px;
  }

  .mm-0 {
    margin-top: 10px;
  }

  .static-head {
    font-weight: 600;
  }

  .beyond-photo {
    margin-bottom: 15px;
  }

  .bulk-img img {
    margin-bottom: 30px;
  }

  .swal-text {
    text-align: center;
  }

  .about-tab-view .p-tabview .p-tabview-panels {
    padding: 0px !important;
  }

  .company-strenght-photo img {
    width: 100%;
    margin-bottom: 20px;
  }

  .company-strenght-photo {
    padding-top: 25px;
  }

  .ourteams-member img {
    width: 100%;
  }

  .member-details {
    width: 90%;
  }

  .ourteams-member img {
    width: 100%;
  }

  .awrads-container {
    overflow: hidden;
    margin-left: 0px;
    padding: 0px 20px;
  }

  .awards-photo {
    width: 70% !important;
  }

  .news-details {
    width: 100% !important;
  }

  .konwledge-photo img {
    width: 100%;
    margin-bottom: 15px;
  }

  /* statics page */
  .movie-banner img {
    width: 100%;
    height: 210px;
    overflow: hidden;
    object-fit: cover;
  }

  /* food */
  .food-bread-mobile {
    display: none !important;
  }

  .food-photo img {
    height: 123.58px !important;
    width: 100% !important;
}
  .veg-icon img {
    width: 100% !important;
  }

  .food-list {
    margin-bottom: 20px !important;
  }

  .food-details h6 {
    font-size: 11px !important;
    height: 35px !important;
    margin-bottom: 15px !important;
  }

  .date-time.food-get-view {
    margin-top: 15%;
  }

  .bg-profile {
    padding: 0px 0px !important;
  }

  .personal-details ul li {
    margin-left: -5px;
  }

  .information-personal {
    margin: 0px 0px !important;
    padding: 0px !important;
    margin-top: 20px !important;
  }

  .mobile-booking-list ul li {
    font-size: 12px !important;
    margin-left: 0px;
  }

  .mobile-booking-list ul li:first-child {
    margin-left: 0px;
  }

  .information-personal .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px 55px 15px 55px !important;
  }

  .food-category .rounded-circle {
    display: inline-block;
  }

  .text-feilds {
    padding: 7.2px 7px 5px 7px !important;
    font-size: 12px !important;
  }

  .incre-decre svg {
    width: 10px !important;
  }

  .mobile-food-space {
    padding-left: 5px;
    padding-right: 5px;
  }

  .side-basket {
    width: 24% !important;
  }

  .title-food {
    width: 11% !important;
  }

  .formats-prev-dynamic {
    display: none !important;
  }

  .text-feilds-poup {
    border: 1px solid #fff0d6;
    padding: 4.2px 12px 3.5px 12px;
  }

  .food-price.m-mobile-popup h5 {
    margin-bottom: 0px !important;
  }

  .foods-details h5 {
    font-size: 18px;
  }

  .food-all img {
    width: 46% !important;
    margin: 0px auto;
    display: flex;
  }

  .veg-icon-popup img {
    width: 100% !important;
  }

  .food-all {

    width: 100% !important;
  }

  .btn-div-price {
    left: 58%;
  }

  /* food */
  .mybooking-box {
    display: block !important;
  }

  .mybooking-orderid {
    text-align: left !important;
  }

  .mybooking-orderid p {
    margin-bottom: 3px !important;
  }

  .food-mybooking-ntn {
    justify-content: flex-start !important;
  }

  .food-line ul li {
    margin-right: 2px !important;
  }

  .my-booking-poster-name {
    display: block !important;
  }

  .mybooking-poster img {
    width: 100% !important;
    margin-bottom: 11px !important;
  }
}

@media (max-width: 430px) {
  .otp-feild input {
    margin-right: 20px !important;
  }

  .mobile-mt-50 {
    margin-top: 37% !important;
  }
}

@media (max-width: 414px) {
  .otp-feild input {
    margin-right: 18px !important;
  }

  .trailer {
    padding: 6px 14px;
    margin-right: 6px;
  }

  .launch-movie-cinema {
    bottom: 23% !important;
  }
}

@media (max-width: 412px) {
  .vip-ticket {
    margin-top: -73px;
  }

  .mobile-mt-50 {
    margin-top: 36% !important;
  }
}

@media (max-width: 393px) {
  .vip-ticket {
    margin-top: -62px;
  }
  .box-slot-moviesession {
    width: 103px !important;
  }
  .launch-movie-cinema {
    bottom: 25% !important;
  }

  .trailer {
    padding: 7px 11px;
    margin-right: 4px;
  }

  .play-mobile {
    top: 52%;
  }

  .dates-active {
    padding: 0px 17.5px !important;
  }

  .dates-inactive {
    padding: 11px 17.5px !important;
  }

  .faq-question h4 {
    font-size: 12px;
  }

  .otp-feild input {
    margin-right: 10px !important;
    margin-left: 5px !important;
  }

  .set-content-mobile p {
    margin-bottom: 20px !important;
  }

  .occupied-box {

    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .disable-person-box {
    margin-left: 7px !important;
    margin-right: 4px !important;
  }

  .unavailable-box-cinema {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .available-box {
    margin-right: 6px !important;
  }

  .mobile-mt-50 {
    margin-top: 37% !important;
  }
}

@media (max-width: 375px) {
  .vip-ticket {
    margin-top: -53px;
  }

  .icon-tick-cinema::before {
    top: 31px !important;
    left: 14px !important;
    height: 75px !important;
  }

  .trailer {
    padding: 7px 11px;
    margin-right: 4px;
  }

  .mobile-mt-50 {
    margin-top: 39% !important;
  }

  .movie-info-showed-mobile {
    font-size: 16px !important;
  }

  .play-mobile {
    top: 50%;
  }

  .dates-active {
    padding: 0px 16.5px !important;
  }

  .dates-inactive {
    padding: 11px 16.5px !important;
  }

  .otp-feild input {
    margin-right: 9px !important;
    margin-left: 4px !important;
  }

  .occupied-box {

    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .disable-person-box {
    margin-left: 7px !important;
    margin-right: 4px !important;
  }

  .unavailable-box-cinema {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .available-box {
    margin-right: 6px !important;
  }

  .search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px 45px 10px 45px !important;
  }

  .movie-detais-vote h6 {
    font-size: 13px !important;
    margin-bottom: 12px !important;
  }

  .movie-detais-vote p {
    font-size: 14px !important;
  }

  .movie-detais-vote h5 {
    font-size: 21px !important;
    margin-bottom: 12px !important;
  }
}

@media (max-width: 360px) {
  .time-select {
    height: 53px !important;
    width: 84px !important;
  }
  .box-slot-moviesession {
    width: 94px !important;
}
  .vip-ticket {
    margin-top: -48px;
  }

  .trailer {
    padding: 6px 8px;
    margin-right: 4px;
  }

  .launch-movie-cinema {
    bottom: 26% !important;
  }

  .dont-cancel-btn {
    font-size: 13px !important;
  }

  .star-vote h6 {
    left: 39% !important;
  }

  .star-vote {
    margin-top: 54% !important;
  }

  .search-dropdown .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 15px 42px 10px 42px !important;
  }

  .play-mobile {
    top: 49%;
  }

  .dates-active {
    padding: 0px 15.5px !important;
  }

  .dates-inactive {
    padding: 11px 15.5px !important;
  }

  .now-movies {
    width: 46%;
  }

  .otp-feild input {
    margin-right: 5px !important;
    margin-left: 6px !important;
  }

  .faq-question {
    width: 90%;
  }

  .set-content-mobile p {
    margin-bottom: 21px !important;
  }

  .movie-info-showed-mobile {
    font-size: 15px !important;
  }

  .mobile-mt-50 {
    margin-top: 40% !important;
  }

  .occupied-box {

    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .disable-person-box {
    margin-left: 7px !important;
    margin-right: 4px !important;
  }

  .unavailable-box-cinema {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .available-box {
    margin-right: 6px !important;
  }

  .mobile-banner-content {
    width: 60%;
  }

  .ticket-movie {
    width: 38%;
    text-align: right;
  }

  .btn-div-price {
    left: 54%;
  }

  .now-movies {
    width: 45% !important;
  }

  .icon-tick-cinema::before {
    top: 31px !important;
    left: 14px !important;
    height: 75px !important;
  }
}

/* Home page Mobile */