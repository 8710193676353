// === primary theme color ==========
.bg-theme-dark1 {
  background-color: $dark-theme-color1;
}

.bg-theme-light1 {
  background-color: $light-theme-color1;
}

.text-theme-dark1 {
  color: $dark-theme-color1;
}

.text-theme-light1 {
  color: $light-theme-color1;
}

// === secondary theme color ==========

.bg-theme-dark2 {
  background-color: $dark-theme-color2;
}

.bg-theme-light2 {
  background-color: $light-theme-color2;
}

.text-theme-dark2 {
  color: $dark-theme-color2;
}

.text-theme-light2 {
  color: $light-theme-color2;
}

// ============================
/* theme color start */
.bg-light-cinema {
  background-color: #C0E1FC !important;
}

.txt-dark-cinema {
  color: #fff !important;
}

.txt-dark-cinema {
  color: #fff !important;
}

.bg-dark-cinema {
  background-color: #1968A8 !important;
}

.bg-light-cinema {
  background-color: #C0E1FC !important;
}

.bg-dark-cinema {
  background-color: #FFCB05 !important;
}

.border-dark-cinema {
  border: 1px solid #FFCB05 !important;
}

.border-dark-cinema {
  border: 1px solid #234B9E !important;
}
