.date-active {
    padding: 8px 23px;
    border-radius: 30px;
    font-weight: 600;
}
.date-inactive {
    padding: 8px 23px;
    border-radius: 30px;
    font-weight: 600;
}
.p-carousel .p-carousel-content .p-carousel-prev{
    display: block;
}
.icon-direction a {
    text-decoration: none;
    margin: auto;
}
.actions ul li {
    float: left;
    list-style: none;
}

.actions ul li a {
    color: #000;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    /* margin-right: 10px; */
}

.movies-forms .p-dropdown-label {
  padding: 10px 10px;
  font-size: 10.5px !important;
  font-family: 'Poppins';
  color: #000 !important;
}


.all-type-trailer {
    position: relative;
    width: 100%;
  }
  
  .down-modal {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .center-hover {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  /* .all-type-trailer:hover .down-modal {
    opacity: 0.3;
  } */
  
  .all-type-trailer:hover .center-hover {
    opacity: 1;
    cursor: pointer;
  }
  p.singer-name {
    font-size: 12px;
    font-weight: 400;
}
.cast-view {
  overflow: hidden;
  padding-right: 10px;
}
.cast-view p {
  font-size: 12px;
  text-align: left;
  margin: 0;
  color: #000;
}
.cast-view img {
  height: 209px;
  border-radius: 9px;
  object-fit: cover;
  overflow: hidden;
  /* width: 130px; */
  width: 100%;
}
.crew p {
  font-size: 12px;
  color: #000;
  margin: 0;
}
.flim-type{
  display: flex;
}
.flims-down {
  margin-right: 35px;
}
.flims-down h6 {
  color: #7A7A7A;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Poppins';
  margin-bottom: 2px;
}


.flims-down h5 {
  font-size: 12px;
  font-family: 'Poppins';
  color: #000;
  margin-bottom: 15px;
}

.sound-mix h6 {
  font-size: 12px;
  font-family: 'Poppins';
  margin-bottom: 2px;
}
.sound-mix p {
  font-size: 12px;
  color: #000;
  margin: 0;
}


.imax-experinces {
  padding: 18.75px 0px 33px 0px;
  height: 340px !important;
}

.imax-logo {padding: 29.25px 0px 39.75px 0px;}

.imax-logo img {
    width: 50%;
}

.cinema-banner-imax h2 {
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 10px;
}

.cinema-banner-imax p {
    font-size: 10.5px;
    color: #fff;
    margin-bottom: 22.5px;
}

.share-icon {
  margin-right: 8px;
}

.line {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 53px;
  background: rgba(255, 255, 255, 0.10);
}

.icon-imax {
  display: flex;
  justify-content: flex-end;
}

.icon-imax img {position: absolute;bottom: 45px;}
.icon-imax img {
  width: 4%;
}
.cinema-head h5 {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 700;
  color: #000;
}
.cinema-head h5 {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 700;
  color: #000;
}

.views-img img {border-radius: 8px;margin-bottom: 20px;}

.views-img {
  position: relative;
}

.allside-view {
  position: absolute;
  right: -19px;
  bottom: 0;
}

.allside-view img {
  width: 57%;
}

.imax-viewes img {
  width: 55%;
  margin-bottom: 20px;
}

.imaxview-details h5 {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Poppins';
  color: #000;
  margin-bottom: 20px;
}

.imaxview-details p {
  font-size: 14px;
  color: #000;
  text-align: justify;
}

.p-sidebar-content::-webkit-scrollbar {
  width: 1px;
}
.readmore {
  cursor: pointer;
  text-decoration: underline;
}
.seat-alert .cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.colse-btn i.pi.pi-times {
  color: #000 !important;
  cursor: pointer;
}
.seat-alert .p-sidebar .p-sidebar-header {
  padding-top: 0px !important;
}
.p-sidebar-bottom .p-sidebar {
  border-radius: 12px 12px 0px 0px;
  height: 10rem;
}
.imax-logo h1 {
  color: #fff;
  font-size: 38px;
}
.cinema-details {
  background: #DCEFFF;
  padding: 12px;
  border-radius: 0px 0px 10px 10px;
}
.cinema-title-heart h2 {
  margin-bottom: 4px;
  color: #045292;
}
.cinema-img img {
  max-width: 100%;
  height: auto;
  overflow: hidden;
  object-fit: fill;
  border-radius: 10px 10px 0px 0px;
  width: 100%;
}
.p-carousel .p-carousel-content .p-carousel-prev svg{
  width: 12px !important;
}
.p-carousel .p-carousel-content .p-carousel-next svg{
  width: 12px !important;
}
.p-carousel .p-carousel-content .p-carousel-prev{
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  border: 1px solid #DDD !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff; 
}

.p-carousel .p-carousel-content .p-carousel-next{
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  border: 1px solid #DDD !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 5px;
  background: #fff; 
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover{
  background: #fff !important;
}
.p-carousel .p-carousel-content .p-carousel-next:focus{
  box-shadow: none !important;
}
.pl-0{
  padding-left: 0px;
}
.cinema-nowshowing p {
  padding-left: 23px;
}
.cinema-nowshowing .p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus{
  box-shadow: none;
}

.cinema-nowshowing .d-flex {
  padding-left: 23px;
}


.show-details{
  display: flex;
    align-items: center;
}

.cinema-synopsis {
  display: flex !important;
}

/* movie-time cinemasession */
.cinemas-card-movietime {
  background: #C0E1FC;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.cinema-movie-poster img {
  border-radius: 6px;
}
.movie-action ul {
  display: flex;
}
.cinema-movie-details h3 {
  font-weight: 700;
  margin-bottom: 3px;
}
.movietime-slot {
  display: flex;
  flex-wrap: wrap;
}
.thriller h6 {
  font-size: 14px;
}
.movie-action ul li {
  list-style: none;
  float: left;
  border: black;
  border-right: 1.5px solid #000;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
}
.movie-action ul li:first-child {
  padding-left: 0px;
}
.movie-action ul li:last-child {
  border-right: none;
}
.thriller p {
  margin-bottom: 0px;
  font-size: 14px;
}
/* movie-time cinemasesstion */
@media (max-width: 575.98px) {
  .imax-experinces {
    height: 164px !important;
  }
}