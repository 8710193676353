.now-showing-filter-cinema .p-tabview .p-tabview-nav-btn.p-link {
  color: #000;
}

.now-showing-filter-cinema .p-tabview .p-tabview-nav-btn.p-link {
  color: #000;
  border-bottom: 2px solid #DDD;
}

.clander-ways {
  border-bottom: 2px solid #DDD;
  border-right: 2px solid #DDD;
  border-left: 2px solid #DDD;
  padding: 0px 10px;
}

.week {
  border-bottom: 2px solid #DDD;
  border-right: 2px solid #DDD;
  border-left: 2px solid #DDD;
  padding: 10px 10px 0px 10px;
  ;
}

.now-showing-filter-cinema .p-tabview .p-tabview-nav-btn.p-link {
  box-shadow: none;
}

.now-showing-filter-cinema .p-tabview .p-tabview-nav {
  border: none;
}

.now-showing-filter-cinema .p-tabview-panels {
  padding: 0px;
}

.comingsoon-bg {
  background: #F1F1F1;
  padding: 0px 0px 100px 0px;
}

.coming-filter {
  box-shadow: 0px 2px 0px #DDD;
  border: 1px solid #DDD;
  background: #fff;
  padding: 9px 15px;
  border-radius: 10px;
  margin: 18.75px 0px;
}

.threater-search {
  margin-bottom: 20px;
}

.fliters-search .p-inputtext {
  font-family: 'Poppins';
  border: 1px solid #EDE8E9;
  background: #F9F9F9;
  height: 36px;
  width: 100%;
  border-radius: 8px;
  font-size: 12px;
}

.fliters-search .p-dropdown:not(.p-disabled):hover {
  border: none;
}

.fliters-search .p-input-icon-left,
.p-input-icon-right {
  display: block;
  width: 90%;
}

.threater-search .p-inputtext {
  font-family: 'Poppins';
  border: 1px solid #D6D6D6;
  background: #F9F9F9;
  height: 50px;
  width: 100%;
  border-radius: 8px;
  font-size: 12px;
}

.threater-search .p-input-icon-left,
.p-input-icon-right {
  display: block;
  width: 100%;
}

.threater-search .p-input-icon-left>.p-inputtext {
  padding-left: 34px;
}

.threater-search .p-inputtext:enabled:hover {
  border-color: transparent !important;
}

.threater-search .p-inputtext:enabled:focus {
  box-shadow: none;
  border: transparent;
}

.big-text {
  font-size: 24px;
  font-weight: 700;
}

.small-text {
  font-size: 14px;
  font-weight: 500;
}

.date-line {
  width: 94%;
  height: 1px;
  background: #DDD;
}

.movies-type {
  font-size: 10.5px;
  color: #808190;
}

.poster-details h6 {
  font-size: 10.5px;
  margin: 0;
  color: #808190;
  font-weight: 400;
}

.year p {
  font-size: 18px;
  margin: 0;
}

.bell-plus-comingsoon {
  border: 1px solid #D6D6D6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
}

.bell-plus-comingsoon-cinema {
  border: 1px solid #D6D6D6;
  background: #234B9E;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.bell-plus-comingsoon-cinema:hover {
  background: #234B9E;
  cursor: pointer;
}

.bell-plus-comingsoon-cinema {
  border: 1px solid #D6D6D6;
  background: #FFCB05;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.bell-plus-comingsoon-cinema:hover {
  background: #FFCB05;
  cursor: pointer;
}

.comingsoon-all {
  box-shadow: 0px 2px 0px #DDD;
  border: 1px solid #DDD;
  background: #fff;
  margin-bottom: 14px;
  border-radius: 10px;
  padding: 25px 20px;
  cursor: pointer;
}

.movies-poster {
  margin-bottom: 14px;
  position: relative;
}

.poster-details h4 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 1px;
}

.poster-details ul {
  display: flex;
}

.poster-details ul li {
  font-size: 14px;
  color: #808190;
  list-style: none;
  float: left;
  margin-right: 5px;
  margin-bottom: 4px;
}

.views-details-comingsoon {
  display: flex;
  margin-bottom: 40px;
}

.btn-coming-view {
  background: #FFF;
  border: 1px solid #D6D6D6;
  padding: 7px 11px 7px 11px;
  border-radius: 6px;
  font-weight: 600;
  margin-right: 8px;
  font-size: 12px;
}



.real {
  color: #000;
}

.poster-details p {
  font-size: 10.5px;
  color: #808190;
  margin-bottom: 12px;
}

.any-city {
  background: #F9F9F9;
  padding: 15px 14px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D3D3D3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.alert-btn-cinema input:checked+.slider {
  background-color: #234B9E;
}

.alert-btn-cinema input:focus+.slider {
  box-shadow: 0 0 1px #234B9E;
}

.alert-btn-cinema input:checked+.slider {
  background-color: #FFCB05;
}

.alert-btn-cinema input:focus+.slider {
  box-shadow: 0 0 1px #FFCB05;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.city-area p {
  font-size: 12px;
  color: #000;
  margin-bottom: 0px;
}

.round-checked {
  position: relative;
}

.cricle-check {
  margin-right: 3px;
}

.round-checked label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: -8px;
  position: absolute;
  top: 0;
  width: 20px;
}

.round-checked label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.round-checked input[type="checkbox"] {
  visibility: hidden;
}

.round-checked input[type="checkbox"]:checked+label {
  background-color: #234B9E;
  border-color: #234B9E;
}

.round-checked input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.nearby-therater {
  display: flex;
  background: #fff;
  padding: 12px 14px 12px 14px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.nearby-therater-border {
  border: 1px solid #D6D6D6;
}

.therater-name h5 {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #000;
}

.therater-name p {
  font-size: 12px;
  color: #000;
}

.distance h5 {
  font-size: 14px;
  font-family: 'Poppins';
  color: #000;
  font-weight: 600;
}

.setalert-icon {
  text-align: center;
  margin-top: -24px;
}

.setalert-icon h6 {
  font-size: 13px;
  font-weight: 700;
  font-family: 'Poppins';
  margin: 0;
  color: #000;
}

.setalert-icon p {
  font-size: 12px;
}

.setalert-icon img {
  /* background: #234B9E; */
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.proceed-btn {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.10);
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 23.7%;
  z-index: 9;
  padding: 20px 0px;
}

.proceeds-btn p {
  font-size: 14px;
  margin-bottom: 22px;
  color: #000;
}

.all-therater {
  margin-bottom: 130px;
}

.proceeds-btn {
  text-align: center;
}

.proceed-flow {
  padding: 6px 100px;
  background: #E9E9E9;
  border: none;
  border-radius: 5px;
  font-family: 'Poppins';
  /* color: rgba(0, 0, 0, 0.50); */
  font-weight: 600;
}

/* .proceed-flow:hover{
    background:#234B9E;
    color: #fff;
} */
.p-sidebar-content::-webkit-scrollbar {
  width: 1px;
}

.city-dropdown p {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.down-arrow img {
  width: 7%;
}

.location-current {
  margin-right: 4px;
}

.download-icon {
  border-radius: 3px;
  border: 1px solid #D6D6D6
}

.cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.colse-btn i.pi.pi-times {
  color: #000 !important;
  cursor: pointer;
}

.p-sidebar .p-sidebar-header {
  padding-top: 3px;
}

.froms-gap {
  margin-left: 7px;
}

.cinema-house {
  text-align: center;
  /* overflow-y: scroll;  */
   height: auto;
   padding-top: 40px;
}

.cinema-house::-webkit-scrollbar{
  width: 0px;
}

.mobile .p-inputtext {
  width: 100%;
  height: 58px;
  border: 1px solid #EDE8E9 !important;
  font-weight: 600;
  color: #000 !important;
  font-family: 'Poppins';
  font-size: 14px;
  padding-bottom: 0px;
}

.mobile .p-float-label input:focus~label,
.p-float-label input:-webkit-autofill~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label,
.p-float-label .p-tooltip-target-wrapper~label {
  top: 8px !important;
}

.mobile .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: none !important;
}

.mobile .p-inputtext:enabled:hover {
  border: 1px solid #EDE8E9 !important;
  background: #fff;
  font-family: 'Poppins';
}

.mobile .p-float-label label {
  margin-top: -0.1rem !important;
  font-family: 'Poppins' !important;
  color: #7A7A7A;
  font-size: 12px;
}

.phone-icon img {
  width: 100% !important;
}

.register-mobile {
  background: #ffff;
  border-radius: 10px;
}

.p-dialog-visible {
  display: flex;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(2px);
}

.p-dialog .p-dialog-content:last-of-type {
  border-radius: 2px !important;
  overflow-y: scroll;
}

.mobile {
  position: relative;
  margin-bottom: 25px;
}

.mobile .p-float-label label {
  top: 43%;
  font-family: 'Poppins' !important;
}

.dropdowns-type{
  font-family: 'Poppins' !important;
  font-size: 12px;
}

.phone-icon {
  position: absolute;
  right: 21px;
  bottom: 17px;
}

.cross-icon {
  position: absolute;
  right: 30px !important;
  top: 18px;
}

.cross-icon .pi.pi-times {
  color: #fff !important;
  font-size: 12px;
  cursor: pointer;
}

.btn-proceeds {
  border: none;
  padding: 10px 12px;
  background: #234B9E;
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
  width: 100%;
  cursor: pointer;
}

.register-btn {
  margin-bottom: 0px;
}

.cinema-house h1 {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  white-space: pre-wrap !important;
  margin-bottom: 10.5px;
}

.cinema-house img {
  margin-bottom: 2.5px;
}

.register-otp {
  margin-top: -13px;
}

.phone-icon img {
  margin-bottom: 0px !important;
}

.cinema-house p {
  margin-bottom: 18px;
  color: #000;
  font-size: 12px;
}

.otp-content {
  margin-bottom: 0px !important;
}

.gift-banner h1 {
  font-size: 22.5px;
  margin: 0;
}

.cinema-house h6 {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #000;
  margin-bottom: 13px;
}

/* .otp-feild{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap:16px;
} */
.from-control {
  padding: 0px 0px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #EDE8E9;
  background: #fff;
  margin-bottom: 20px;
}

.bell-icons {
  margin-top: 24px;
}

.alert-spaces {
  margin-bottom: 4px !important;
}

.modal-line {
  background: #D6D6D6;
  width: 32%;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
}

.notify-content {
  margin-bottom: 14px !important;
}

.whatsapp-content {
  margin: 14px 0px 22px 0px;
}

.whatspp-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9F9F9;
  padding: 15px 15px;
  margin-bottom: 20px;
}

.whatsapp-icon p {
  margin: 0;
}

.whts-icon img {
  margin-bottom: 0px;
  margin-right: 6px;
}

.otp-feild input {
  margin-left: 14px;
  font-family: 'Poppins';
  margin-bottom: 10.5px;
}

.otp-feild input:first-child {
  margin-left: 0px;
}

.otp-feild input:focus-visible {
  outline: none !important;
}

.otpfeild {
  width: 45px;
  text-align: center !important;
  height: 60px;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px;
  border: none;
}

.cinema-house img {
  width: 40%;
}

.movies-forms .p-dropdown {
  height: 36px;
  background: #F9F9F9;
  border-color: #E2E2E2;
}

.movies-forms .p-dropdown-label {
  padding: 0px 0px;
}

.movies-forms .p-dropdown-label {
  padding: 8px 10px;
  font-size: 12px;
  font-family: 'Poppins';
  color: #000 !important;
}

.movies-forms .p-icon {
  width: 2px;
  height: 2px;
}

.movies-forms .p-icon {
  width: 23px;
  height: 12px;
  color: #000;
}

/* .now-showing-filter-cinema .p-tabview .p-tabview-nav-btn.p-link::after {
    content: "";
    position: absolute;
    margin-left: 26px;
    height: 31px;
    width: 3px;
    background: #DDD;
} */

/* .bg .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover{
  border: none ;
} */
.p-dialog .p-dialog-content::-webkit-scrollbar {
  width: 1px;
  padding: 20px;
}

.bell-icons {
  width: 15% !important;
}

.whts-icon img {
  width: 12% !important;
}

.cross-icon.desktop-view-cross {
  right: 18px !important;
}

/* mobile coming soon */
.coming-tit h6 {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
}

.mobile.input-leave.adv-msg .p-inputtext {
  height: 114px;
}

/* mobile coming soon */

.cs-viewdetail-cinema {
  border: 1px solid gray;
  border-radius: 5px;
  /* padding: 10px 15px; */
  color: #000;
}

/* .cs-viewdetail-cinema:hover {
  border: 1px solid #FFCB05;
  border-radius: 5px;
  padding: 10px 15px;
  color: #000;
  background-color: #FFCB05;
} */

.cs-viewdetail-cinema {
  /* border: 1px solid #fff; */
  border-radius: 5px;
  /* padding: 10px 15px; */
  color: #000;
  /* background-color: #fff; */
  /* font-family: Poppins; */
  font-size: 12px;
  font-weight: 700;
}

/* .cs-viewdetail-cinema:hover {
  border: 1px solid #234B9E;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
  background-color: #234B9E;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
} */

.calander-allyear-show ul li {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -.1px;
  color: #000;
  display: inline;
  padding: 6px 22px;
  padding-top: 6px !important;
  float: left;
  list-style: none;
  white-space: nowrap;
}

.year-show {
  padding: 0px 22px;
}

.calander-month ul li:hover {
  color: #FFCB05;
}
.p-card .p-card-footer{
  padding: 4px 0px !important;
}
.active-month {
  color: #FFCB05;
  border-bottom: 1px solid #FFCB05;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -.1px;
  display: inline;
  padding: 4px 12px;
  padding-top: 6px !important;
  min-width: 75px;
  max-width: 75px;
  white-space: nowrap;
}

.all-week {
  display: flex;
  overflow-x: scroll;
  width: 1093px;
  max-width: 100%;
  margin: 0 auto;
}

.all-week::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.all-week::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.all-week::-webkit-scrollbar-thumb {
  background: #888; 
}

.show-week {
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -.1px;
  color: #000;
  display: inline;
  padding: 6px 12px;
  padding-top: 6px !important;
  min-width: 95px;
  max-width: 96px;
}

.seat-alert .down-arrow {
  display: inline;
}

.cs-viewdetail-cinema {
  border: 1px solid #D6D6D6;
  border-radius: 5px;
  /* padding: 9px 15px; */
  color: #000;
  font-family: 'Poppins';
  font-size: 12px;
  background: #ffff;
  font-weight: 600;
  margin-right: 8px;
}
.pdfError{
  color: red;
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 600;
}

.coming-sson-play-btn {
  position: absolute;
  top: 35%;
  left: 33%;
}
.p-dialog .p-dialog-header {
  background: none;
  padding: none;
  box-shadow: none;
}
.p-dialog .p-dialog-content{
  background: none;
}
.p-dialog{
  box-shadow: none;
}
.play-trailers {
  width: 100% !important;
  height: 100% !important;
}