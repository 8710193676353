/* show times page banner css start */
.cinema-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: unset !important;
    height: 450px;

}
/* .another-overlay {
    background-image: linear-gradient(to right, rgba(31.5, 31.5, 52.5, 1) calc((50vw - 170px) - 340px), rgba(31.5, 31.5, 52.5, 0.84) 50%, rgba(31.5, 31.5, 52.5, 0.84) 100%);
} */
.movie-slide {
    padding: 16px 62px 20px 62px;
}
.movie-details{
    text-align: left;
}

.proper-screen {
    position: relative;
}
/* .line-crumb {
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0.10);
    height: 1px;
    top: 46px;
} */
.share-movies {
    display: flex;
    justify-content: space-between;
}
.movie-details p {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 11px;
    align-items: center;
    width: 50%;
}
/* .movies-experince {
    display: flex;
    margin-bottom: 24px;
} */

/* .movies-experince ul li {
    float: left;
    background: #fff;
    margin-right: 4px;
    padding: 8px 14px;
    border-radius: 15px;
    list-style: none;
    border: 1px solid;
    margin-bottom: 10px;
} */



.movies-experince ul li a {
    text-decoration: none;
    color: #ffff;
    font-size: 10px;
    font-weight: 500;
    cursor: inherit;
}

.movie-details h6 {
    color: #fff;
    background: rgba(2, 2, 4, 0.80);
    text-align: left;
    border: 1px solid #fff;
    padding: 6px 8px 6px 8px;
    border-radius: 30px;
    font-size: 11px;
    display: inline-block;
    margin-bottom: 15px;
}
.movie-details h1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    margin-bottom: 15px;
}

.view-detail {
    border: none;
    padding: 9.75px 8px;
    font-size: 16px;
    color: white;
    margin-left: -5px;
    /* font-weight: 600; */
    /* margin-bottom: 50px; */
}

.view-detail span {
   color: white;
   text-decoration: underline;
   font-size: 12px;
   text-transform: capitalize;
   margin-bottom: 10px;
}
.movie-trailer {
    text-align: left;
    padding-right: 22px;
    position: relative;
}
.movie-trailer img {
    width: 100%;
    height: 100%;
}

.movie-slide .movie-trailer img {
    height: 270px;
    width: 220px;
    object-fit: contain;
    margin-top: 20px;
}
.movie-slide .movie-details{
    margin-top: 20%;
}


.video-icons {
    position: absolute;
    top: 54%;
    left: 45%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.offers-discount {
    display: flex;
}
.offers-details h5 {
    font-size: 16px;
    color: #fff;
    font-weight: 800;
    margin: 0px;
}
.offers-img img {
    padding-right: 10px;
}

.show-spaces {
    margin-bottom: 18.5px;
    padding-right: 7px;
    padding-left: 7px;
}

.video-icon {
    position: absolute;
    top: 135px;
    /* right: 109px; */
    cursor: pointer;
}

.video-icon img {
    width: 48px !important;
    height: 48px;
}

.btn-gap {
    margin-bottom: 40px !important;
}

.calnder-current ul li {
    list-style: none;
    float: left;
    /* margin-right: 40px; */
}

.dates-active {
    text-align: center;
    padding: 10px 30.5px;
    /* border: 0px solid #CC1821; */
    background: #1968A8;
    border-bottom: 2px solid #CC1821;
}

.dates-inactive {
    text-align: center;
    padding: 10px 30.5px;
}

.dates-cinema-active{
    text-align: center;
    padding: 10px 30.5px;
    background: $dark-theme-color2;
    border-bottom: 2px solid $dark-theme-color1;
    color: #fff;
}
.dates-cinema-inactive {
    text-align: center;
    padding: 10px 30.5px;
}
.dates-cinema-active h5 {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 0;
    /* line-height: 1.1; */
}
.day {
    font-size: 12px;
}

.month {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}

.dates-active h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    color: #fff;
}
.dates-inactive h5 {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 0;
}
.pbt-20{
    padding: 0px 21px;
}

.bg-booking {
    background: #F1F1F1;
    height: auto;
}

.card-fliter {
    box-shadow: 0px 2px 0px #DDD;
    border: 1px solid #DDD;
    background: #bfe1fc;
    padding: 14px 20px;
    border-radius: 10px;
    margin: 18.75px 0px;
}

.fliter-search  .p-input-icon-left, .p-input-icon-right{
    display: block;
}

.fliter-search .p-inputtext {
    font-family: 'Poppins';
    border: 1px solid #EDE8E9;
    background: #F9F9F9;
    height: 36px;
    width: 100%;
    border-radius: 8px;
    font-size: 12px;
}
.fliter-search .p-inputtext:enabled:hover{
    border-color: #EDE8E9;
}
.seat-view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.seat-view {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.seat-color {
    width: 10px;
    height: 10px;
    display: inline-block;
    /* background: #007D23; */
    margin-right: 10px;
    position: relative;
    top: 1.1px;
}

.seat-view p {
    margin-right: 13px;
    margin-bottom: 0px;
    font-size: 12px;
}
.divide-line .p-divider.p-divider-horizontal {
    margin: 13px 0px 5px 0px;
}
.cinema-movie-time .p-accordion-header-text{
    width: 100%;
}
.cinema-movie-time .icon-subtract {
    position: absolute;
    right: 16px;
    top: 21px;
    display: none;
}
.cinema-movie-time .icon-add {
    position: absolute;
    right: 16px;
    top: 25px;
    display: none;
}
.cinema-movie-time .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    position: absolute;
    right: 3px; 
}
.direaction-maps span {
    margin-right: 18px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
}
.cinema-movie-time {
    margin-bottom: 50px;
}
.cinema-movie-time .p-accordion .p-accordion-tab {
    background-color: #bfe1fc;
    border-radius: 12px 12px;
    margin-bottom: 10.5px;
    box-shadow: 0px 2px 0px #DDD;
}
.cinema-movie-time .p-accordion .p-accordion-content{
    border: none;
    border-radius: 12px 12px;
    padding: 8px 20px 15px 20px;
    background-color: #bfe1fc;
}
.cinema-movie-time .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: none;
}
.cinema-movie-time .p-accordion .p-accordion-header .p-accordion-header-link{
    border: none;
   background: none;
}
.cinema-movie-time .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link{
    background: #bfe1fc;
    border: none;
    color: #000;
}

.cinemas-name h2 {
    font-family: 'Poppins';
    font-size: 13.5px;
    font-weight: 700;
    color: #045292;
    margin-bottom: 4px;
}

.cinemas-name p {
    font-size: 10.5px;
    font-family: 'Poppins';
    color: #808190;
    margin: 0;
}
.cinema-movie-time {
    margin-bottom: 38px;
}


.time-select {
    border: 1px solid #007D23;
    background: rgba(0, 125, 35, 0.04);
    padding: 11px 3px 11px 3px;
    /* padding: 11px 10px 11px 10px; */
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    /* height: 100%; */
    width: 100%;
    height: 50px;
}

.show-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0px;
}
.show-details-icon{
    /* margin-left: 15px; */
    display: flex;
    justify-content:center;
    align-items: center;
}
.eng {
    position: absolute;
    bottom: 40px;
    background: #fff;
    /* left: 30%; */
    border-radius: 8px;
    padding: 0px 3px;
}
.eng h6 {
    font-size: 10px;
    font-weight: 700;
    font-family: 'Poppins';
    padding: 3px 4px;
    margin: 0;
    color: #045292;
    text-transform: uppercase;
}

.cinema-movie-time .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    box-shadow: none;
}
.show-times h5 {
    font-size: 12px;
    font-weight: 900;
    color: #007D23;
    font-family: 'Poppins';
    margin-bottom: 0px;
    /* margin-top: 5px; */
}

.show-times p {
    font-size: 9px;
    font-family: 'Poppins';
    font-weight: 800;
    margin: 0;
    color: #000;
}

.icon-subtitles {
    display: flex;
}
.bread-movies ul li {
    float: left;
    list-style: none;
    font-weight: 500;
    font-size: 12px;
    margin-right: 30px;
    color: rgba(255, 255, 255, 0.50);
    text-transform: uppercase;
}
.movies-forms .p-multiselect {
    height: 36px;
}
.movies-forms .p-multiselect:not(.p-disabled):hover{
    border-color: #E2E2E2;
}
.movies-forms .p-multiselect-label{
    padding: 0px 0px;
}
.movies-forms .p-multiselect-label {
    padding: 10px 10px;
    font-size: 10.5px !important;
    font-family: 'Poppins';
    color: #000 !important;
}
.movies-forms .p-icon{
    width: 2px;
    height: 2px;
}
.movies-forms .p-icon {
    width: 23px;
    height: 12px;
    color: #000;
}
.show-times {
    text-align: center;
}
.movie-session-box-movies{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chair-icon img {
    position: static;
    margin-left: 15px;
    margin-bottom: 5px;
    padding-right: 2px;
}
.also-arrow .p-buttonset .p-button:last-of-type {
    padding: 3.8px 5px 3.1px 5px !important;
    border-left: none !important;
}
.heat-d {
    margin-right: 18px;
}
.cinemas-name{
    width: 100%;
}
.box-listed-cinema {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select-slot-movies {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.box-slot-moviesession {
    width: 14.28%;
}
.adult-terms {
    font-size: 14px;
    text-align: justify;
}
.popup_rate .p-dialog-content{
    padding: 0px;
}

.calnder-current span.day {
    position: relative;
    bottom: 1px;
}

.msession-video-icons img {
    height: auto !important;
    width: 20% !important;
    margin: 0 auto;
    text-align: center;
    display: flex;
    position: absolute;
    top: 40%;
    left: 30%;
}

.tariler-show {
    width: 70% ;
    height: 95%;
    margin: 0 auto;
}


.date-fix-moviesession {
    position:sticky;
    top: 6.1rem;
    z-Index:9;
    background-Color: #fff;
}


/* .breadcurmb-imax ul li {
    list-style: none;
    float: left;
    margin-right: 17px;
    margin-bottom: 22px;
} */
/* show times page banner css end */


/* mobile moviesession css */
.movies-arrow-fix-mobile {
    margin-right: 15px;
}
.movies-name-titile-mobile {
    width: 100%;
    overflow: hidden;
}
.movie-info-showed-mobile {
    font-size: 18px !important;
    font-weight: 700 !important;
    margin: 0;
    overflow: hidden;
    animation: marquee 10s linear infinite;
}
@keyframes marquee {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(-100%);
    }
  }
.payment-header-item-movie {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.movie-name-mobile-show {
    display: flex;
    align-items: center;
}
.search-movie-mobile i {
    font-size: 22px;
    font-weight: 600;
}

/* mobile moviesession css */

.calnder-current ul {
    display: flex;
    overflow-x: scroll;
}
.calnder-current ul::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

.showtime-slider-cinema .p-slider .p-slider-range{
    background-color: #FFCB05;
}  
.showtime-slider-cinema .p-slider .p-slider-handle{
    border: 2px solid #FFCB05;
}
.showtime-slider-cinema .p-slider:not(.p-disabled) .p-slider-handle:hover{
    background-color: #FFCB05;
}

.showtime-slider-cinema .p-slider .p-slider-range{
    background-color: #1968a8;
} 
.showtime-slider-cinema .p-slider .p-slider-handle{
    border: 2px solid #1968a8;
}
.showtime-slider-cinema .p-slider:not(.p-disabled) .p-slider-handle:hover{
    background-color: #1968a8;
}

.movietime-bread {
    background: #DCEFFF;
}

.movie-accordians{
    margin-top: 38px;
}
  
@media (max-width: 575.98px) {
    .show-in-desktop-view {
        display: none !important;
    }
}
.icon-trailer {
    position: absolute;
    top: 30px;
    left: 30px;
    transform: translate(-50%, -50%);
}
.icon-trailer img {
    width: 60%;
}