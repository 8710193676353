@media screen and (min-width: 1695px) {
    .view-container {
      max-width: 1800px;
      margin: 0 auto;
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .card-holder-name h6{
      left: 41%;
    }
    .day-box.day-box-profile {
      position: absolute;
      left: 66%;
  }
    .food-show-gst{
      height: 226px !important;
    }
    .more-content{
      height: 390px !important;
    }
    .filter-btn {
      padding: 13px 28px !important;
    }
    .now-movie {
      grid-gap: 44px;
    }
    .date-show {
      padding: 13px 12px !important;
    }
    .proceed-btn {
      width: 15.7% !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white;
      font-size: 14px;
      font-weight: bold;
      margin: 0 65px;
    }
    .otp-feild input {
      margin-left: 16px !important;
      width: 96px !important;
      height: 96px !important;
    }
    .otp-feild input:first-child {
      margin-left: 0px !important;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -2px !important;
  }
  .box-slot-moviesession {
    width: 9.28% !important;
  }
  .title-food {
    width: 29% !important;
    text-align: right !important;
  }
  .side-basket {
    width: 21% !important;
  }
  .title-food {
    width: 14% !important ;
    text-align: right;
  }
  .text-feilded {
    padding: 0px 25px !important;
  }
  .quick-left .p-dropdown {
    width: 335px !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.75px 14px !important;
  }
  .select-date {
    width: 14% !important;
  }
  }
  @media screen and (min-width: 1800px) and (max-width: 1920px) {
    .cinema-book-summary{
      width: 70% !important;
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .view-container {
      max-width: 1600px;
      margin: 0 auto;
    }
    .day-box.day-box-profile {
      left: 70%;
  }
  .card-holder-name h6 {
    left: 40.9%;
  }
    .food-show-gst{
      height: 226px !important;
    }
    .seatlayout-tnc {
      height: 32vh !important;
  }
    .more-content{
      height: 390px !important;
    }
    .msession-video-icons img {
      left: 18% !important;
    }
    .foods-layout{
      height: 780px !important;
      margin-bottom: 180px !important;
    }
    .seat-layout {
      height: 90% !important;
    }
    .now-movie {
      grid-gap: 39px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 50px;
    }
    .proceed-btn {
      width: 17.7% !important;
    }
    .otp-feild input {
      margin-left: 16px !important;
      width: 83px !important;
      height: 83px !important;
    }
    .otp-feild input:first-child {
      margin-left: 0px !important;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -2px !important;
  }
  .box-slot-moviesession {
    width: 10.28% !important;
  }
  .side-basket {
    width: 28% !important;
  }
  .banner-indicator-active {
    position: relative;
    left:6px;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.75px 14px !important;
  }
  .select-date {
    width: 12% !important;
  }
  .movie-trailers{
    height: 275px !important;
  }
  .select-date .p-dropdown .p-dropdown-trigger{
    width: 1rem;
  }
  .launch-movie-cinema{
    bottom: 21% !important;
  }
  }
  @media screen and (min-width: 1694px) and (max-width: 1800px) {
    .cinema-book-summary{
      width: 77% !important;
    }
    .monthly-pack{
      right:18% !important;
    }
    .launch-movie-cinema{
      bottom: 21% !important;
    }
    .zone{
      left: 30% !important;
    }
    .seat-layout {
      height: 90% !important;
    }
    .launch-movie-cinema{
      bottom: 18%;
    }
    .card-holder-name h6 {
      left: 38.9%;
    }
    .seatlayout-tnc {
      height: 40vh !important;
  }
    .foods-layout{
      height: 820px !important;
      margin-bottom: 180px !important;
    }
    .msession-video-icons img{
      left: 20% !important;
      width: 20% !important;
    }
    .quick-left .p-dropdown {
      width: 274px !important;
    }
    .view-container {
      width: 1500px;
      margin: 0 auto;
    }
    .now-movie {
      grid-gap: 36px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 44px;
    }
    .proceed-btn {
      width: 18.9% !important;
    }
    .otp-feild input {
      margin-left: 19px !important;
      width: 76px !important;
      height: 76px !important;
    }
    .otp-feild input:first-child {
      margin-left: 0px !important;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -2px !important;
  }
  .box-slot-moviesession {
    width: 11.28% !important;
  }
  .side-basket {
    width: 30% !important;
  }
  }
  @media screen and (min-width:1500px) and (max-width:1600px) {
    .cinema-book-summary{
      width: 83% !important;
    }
    .launch-movie-cinema{
      bottom: 21% !important;
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .seat-layout {
      height: 88% !important;
    }
    .launch-movie-cinema{
      bottom: 20%;
    }
    .trailer-box .p-galleria-thumbnail-item {
      flex: 1 0 18% !important;
  }
    .seatlayout-tnc{
      height: 43vh !important;
    }
    .msession-video-icons img {
      left: 34% !important;
    }
    .more-content{
      height: 390px !important;
    }
    .movie-trailer img {
      width: 100% !important;
      height: 100%;
    }
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #000;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .proceed-btn {
      width: 21.3% !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -1px !important;
  }
  .box-slot-moviesession {
    width: 14.28% !important;
  }
  .quick-left .p-dropdown {
    width: 226px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:3px !important;
  }
  .select-date {
    width: 16% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.75px 14px !important;
  }
  .foods-layout {
    height: 690px !important;
    margin-bottom: 180px !important;
  }
  }
  @media screen and (min-width: 1440px) and (max-width: 1694px) {
    .seat-layout {
      height: 88% !important;
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .day-box.day-box-profile {
      position: absolute;
      left: 63%;
  }
    .card-holder-name h6 {
      left: 40.9%;
    }
    .view-container {
      width: 1400px;
      margin: 0 auto;
    }
    .now-movie {
      grid-gap: 32px;
    }
    .filter-btn {
      padding: 11px 28px !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .otp-feild input {
      margin-left: 16px !important;
      width: 67px !important;
      height: 67px !important;
    }
    .otp-feild input:first-child {
      margin-left: 0px !important;
    }
    .text-feilded {
      padding: 0px 10px !important;
  }
  .side-basket {
    width: 26% !important;
  }
  .title-food {
    width: 21% !important;
    text-align: right !important;
  }
  .quick-left .p-dropdown{
    width: 233px !important;
  }
  }
  @media (min-width: 1450px) and (max-width: 1500px) {
    .video-icon {
    }
    .monthly-pack{
      right:18% !important;
    }
    .launch-movie-cinema{
      bottom: 20% !important;
    }
    .zone{
      left: 30% !important;
    }
    .foods-layout {
      height: 730px !important;
    }
    .day-box.day-box-profile {
      position: absolute;
      left: 57%;
  }
    .msession-video-icons img {
      left: 33% !important;
    }
    .title-food {
      width: 18% !important;
  }
  
    .more-content{
      height: 460px !important;
    }
    
    .seat-layout {
      height: 88% !important;
    }
    .movie-trailer img {
      width: 100% !important;
      height: 100%;
    }
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #808190;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .proceed-btn {
      width: 21.3% !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -1px !important;
  }
  .box-slot-moviesession {
    width: 14.28% !important;
  }
  .quick-left .p-dropdown {
    width: 217px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:-8px !important;
  }
  .select-date {
    width: 17% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 9.5px 14px !important;
  }
  
  }
  @media (min-width: 1440px) and (max-width: 1450px) {
    .video-icon {
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .foods-layout {
      height: 730px !important;
    }
    .msession-video-icons img {
      left: 33% !important;
    }
    .title-food {
      width: 18% !important;
  }
  
    .more-content{
      height: 460px !important;
    }
    
    .seat-layout {
      height: 88% !important;
    }
    .movie-trailer img {
      width: 100% !important;
      height: 100%;
    }
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #808190;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .proceed-btn {
      width: 21.3% !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -1px !important;
  }
  .box-slot-moviesession {
    width: 14.28% !important;
  }
  .quick-left .p-dropdown {
    width: 233px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:-8px !important;
  }
  .select-date {
    width: 17% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 9.5px 14px !important;
  }
  
  }
  @media (min-width: 1400px) and (max-width: 1439px) {
    .cinema-book-summary{
      width: 94% !important;
    }
    .launch-movie-cinema{
      bottom: 20% !important;
    }
    .monthly-pack{
      right:18% !important;
    }
    .zone{
      left: 30% !important;
    }
    .video-icon {
    }
    .foods-layout {
      height: 730px !important;
      margin-bottom: 180px !important;
    }
    .msession-video-icons img {
      left: 33% !important;
    }
    .title-food {
      width: 18% !important;
  }
  
    .more-content{
      height: 460px !important;
    }
    
    .seat-layout {
      height: 88% !important;
    }
    .movie-trailer img {
      width: 100% !important;
      height: 100%;
    }
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #808190;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-family: "Lato", sans-serif;
    }
    .proceed-btn {
      width: 21.3% !important;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -1px !important;
  }
  .box-slot-moviesession {
    width: 14.28% !important;
  }
  .quick-left .p-dropdown {
    width: 217px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:-8px !important;
  }
  .select-date {
    width: 17% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 9.5px 14px !important;
  }
  
  }
  /* @media (min-width: 1400px) and (max-width: 1500px) {
    .video-icon {
    }
    .foods-layout {
      height: 730px !important;
    }
    .msession-video-icons img {
      left: 33% !important;
    }
    .title-food {
      width: 18% !important;
  }
  
    .more-content{
      height: 460px !important;
    }
    
    .seat-layout {
      height: 88% !important;
    }
    .movie-trailer img {
      width: 100% !important;
      height: 100%;
    }
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #808190;
      font-size: 14px;
       font-family: "Lato", sans-serif;
    }
    .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
       font-family: "Lato", sans-serif;
    }
    .proceed-btn {
      width: 21.3% !important;
    }
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -7px !important;
      top: -1px !important;
  }
  .box-slot-moviesession {
    width: 14.28% !important;
  }
  .quick-left .p-dropdown {
    width: 217px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:-8px !important;
  }
  .select-date {
    width: 17% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 9.5px 14px !important;
  }
  } */
  @media screen and (min-width: 1100px) and (max-width: 1439px) {
    .view-container {
      width: 1140px;
      margin: 0 auto;
    }
  
    .day-box.day-box-profile {
      position: absolute;
      left: 57%;
  }
   
    .msession-video-icons img {
      left: 39% !important;
    }
    .more-content{
      height: 390px !important;
    }
    .now-movie {
      display: flex !important;
      flex-wrap: wrap;
      grid-gap: 27px;
    }
    .nav-center {
      font-size: 13px !important;
       font-family: "Lato", sans-serif;
    }
    .filter-btn {
      padding: 12px 20px !important;
      font-size: 12px;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 35px;
    }
    .proceed-btn {
      width: 23.7% !important;
    }
    .side-basket {
      width: 29% !important;
  }
  .text-feilded {
    padding: 1px 10px !important;
    font-size: 11px !important;
    margin-top: 8px;
  }
  } 
  @media (min-width: 1199.98px) and (max-width: 1290px) {

    .seat-layout {
      height: 90% !important;
    }
    .foods-layout {
      height: 830px !important;
      margin-bottom: 180px !important;
    }
    .msession-video-icons img {
      left: 30% !important;
    }
    .food-title-name {
      width: 50% !important;
    }
    .side-basket {
      width: 32% !important;
  }
  .title-food {
    font-size: 9px !important;
  }
    .title-food {
      width: 13% !important;
    }
    .more-content {
      height: 390px !important;
    }
    /* .time-select{
      width: 85% !important;
    } */
    .movie-show-desktop-view {
      display: block;
      overflow: hidden !important;
  }
    .icon-subtitles{
      right: -4px !important;
    }
    .box-slot-moviesession {
      width: 16.28% !important;
  }
  /* .otp-feild input {
    width: 52px !important;
    height: 52px !important;
  } */
  .otp-feild input:first-child{
    margin-left: 0px !important;
  }
  .text-feilds {
    padding: 3px 20px 3px 20px;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.92px 14px !important;
  }
  .select-date .p-dropdown .p-dropdown-label{
    font-size: 12px !important;
  }
  .select-date .p-dropdown .p-dropdown-trigger{
    width: 1rem !important;
  }
  .awrads-container {
    margin-left: 79px !important;
  }
  .mobile .p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label {
    top: 6px !important;
  }
  .p-dialog .p-dialog-content:last-of-type{
    height: auto !important;
  }
  .otp-feild input {
    margin-left: 21px !important;
  }
  .banner-indicator-active {
    position: relative;
    left:-8px;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.92px 14px 7.92px 14px !important;
  }
  .select-date {
    width: 17% !important;
  } 
  .proceed-btn {
    width: 25.2% !important;
  }
  .seatlayout-tnc {
    height: 43vh !important;
  }
  }
  @media (min-width: 991.98px) and (max-width: 1199.98px) {x
    .title-food {
      font-size: 9px !important;
    }
    .now-movies {
      width: 22.8% !important;
  }
    .food-title-name {
      width: 40% !important;
    }
    .side-basket {
      width: 31% !important;
    }
    .text-feilds.borderless {
      padding: 0px 8px 0px 8px !important;
      font-size: 12px !important;
    }
    .side-basket svg {
      width: 8px !important;
    }
    .incre-decre svg {
      width: 9px !important;
    }
    .box-slot-moviesession {
      width: 17.28% !important;
  }
  .otp-feild input {
    width: 43px !important;
    height: 43px !important;
  }
  .otp-feild input {
    margin-left: 12px !important;
  }
  .otp-feild input:first-child{
    margin-left: 0px !important;
  }
  }
  @media (max-width: 1399.98px) {
    .quick-left .p-dropdown .p-dropdown-label.p-placeholder {
      color: #000;
      font-size: 12px;
       font-family: "Lato", sans-serif;
    }
    .day-box.day-box-profile {
      position: absolute;
      left: 50%;
  }
    .quick-left .p-calendar .p-inputtext {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
       font-family: "Lato", sans-serif;
      color: #808190;
    }
    /* banner styling */
    .banner-indicator {
      list-style: none;
      cursor: pointer;
      color: gray !important;
      font-size: 14px;
      margin: 0 25px;
    }
    .banner-indicator-active {
      list-style: none;
      cursor: pointer;
      color: white !important;
      font-size: 14px;
      font-weight: bold;
      margin: 0 27px;
    }
    .icon-subtitles {
      position: relative !important;
      right: -5px !important;
      top: -3px !important;
    }
    .box-slot-moviesession {
      width: 14.28% !important;
  }
  .title-food{
    width: 18% !important;
  }
  .select-time .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 8.92px 14px 7.92px 14px !important;
    /* background: #dceffe; */
    border-bottom: 2px solid #ddd !important;
    
  }
  .mobile .p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label {
    top: 6px !important;
  }
  .p-dialog .p-dialog-content:last-of-type{
    height: auto !important;
  }
  }
  @media (min-width: 767.98px) and (max-width: 991.98px) {
    .seat-flow-custom-scroll {
      display: block !important;
    }
    .nav-center .p-inputtext.p-component {
      font-size: 13px;
    }
    .grand-content {
      padding-right: 15px;
  }
    .bg-point-needed-point{
      width: 61.5%;
      margin-right: 12px;
    }
    .all-links {
      display: none !important;
    }
    .nav-center {
      font-size: 12px;
    }
    .nav-center .p-dropdown .p-dropdown-trigger {
      width: 28px;
    }
    .nav-center .p-dropdown.p-component.p-inputwrapper {
      font-size: 13px;
    }
    .logos-cinema {
      width: 100%;
    }
    .filter-btn {
      padding: 11px 10px !important;
    }
    .movie-slides {
      padding: 28px 93px !important;
    }
    .now-movie {
      grid-gap: 24px;
    }
    .now-movies {
      width: 30% !important;
  }
    .book-tickets-btn {
      padding: 7px 16px !important;
      font-size: 13px;
      border-radius: 4px;
    }
    .view-details {
      font-size: 11px;
      white-space: pre;
      padding: 3px 4px !important;
    }
    .bell-plus img {
      width: 84%;
    }
    .bell-plus {
      text-align: center;
    }
    .experiences-img img {
      margin-bottom: 20px;
    }
    .quick-book p {
      font-size: 15px;
    }
    .desktop-view {
      display: none !important;
    }
    .tablet-view {
      display: block !important;
    }
    .tablet-view .p-button {
      border: none;
      background: none;
      color: #000;
      font-size: 16px;
    }
    .tablet-view .pi {
      font-size: 24px;
    }
    .tablet-view .p-button:enabled:hover,
    .p-button:not(button):not(a):not(.p-disabled):hover {
      background: none;
      border: none;
      color: #000;
    }
    .quick-booking-from {
      flex-wrap: wrap;
    }
    .quick-left {
      margin-right: 10px;
      margin-left: 0px !important;
      display: inherit !important;
      margin-bottom: 8px;
      width: inherit !important;
    }
    .date-show {
      margin-bottom: 8px;
      margin-right: 10px;
    }
    .redmee-coupon h6 {
      font-size: 11px;
      margin: 0;
      padding-right: 3px;
    }
    .vail-date {
      font-size: 10px;
    }
    .view-details-btn {
      font-size: 10px;
      padding: 7px 7px !important;
    }
    .all-btn {
      margin-top: 15px;
    }
    .media {
      display: none;
    }
    .media-tabview {
      display: block;
      padding-top: 6px;
    }
    .media-tabview ul li {
      float: left;
      color: grey;
      list-style: none;
      font-size: 21px;
      margin-right: 21px;
    }
    .redmee-coupon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .movie-details h1 {
      font-size: 32px !important;
    }
    .action ul li a {
      font-size: 14px;
    }
    .tabview-menu {
      display: flex;
      align-items: center;
      padding: 7px 4px;
  }
  .more-content{
    height: 200px !important;
  }
    .tab-view-menu ul li {
      list-style: none;
      color: #000;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      width: 100%;
    }
    .tablet-view .p-sidebar .p-sidebar-content {
      padding: 0px 0px 0px 0px !important;
    }
    .tab-view-menu .p-sidebar .p-sidebar-content {
      padding: 0 !important;
    }
    .movie-name h1 {
      font-size: 25px !important;
    }
    .movie-icon {
      text-align: right !important;
    }
    .copy-right {
      padding-top: 8px;
      font-size: 14px;
    }
    .col-4 {
      flex: 0 0 auto;
      width: 33.33333333% !important;
    }
    .tab-view-menu .p-dropdown.p-invalid.p-component {
      border-color: none !important;
    }
    .tab-view-menu .p-dropdown {
      background: none;
      border: none;
    }
    .tab-view-menu .p-dropdown .p-dropdown-label.p-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      padding: 0;
    }
    .tab-view-menu .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: none;
    }
    .icon-direction p {
      font-size: 11px;
    }
    .showtimes-cinemas-name p {
      font-size: 12px !important;
    }
    .direaction-maps span {
      margin-right: 10px !important;
      font-size: 14px;
    }
    .eng {
      bottom: 31px !important;
    }
    .banner-indicator-active {
      position: relative;
      left: -27px;
  }
   
  .date-show {
      width: 42%;
  }
   
  .cinemas-active, .cinemas-inactive {
      padding: 4.5px 37.25px !important;
  }
   
  .quick-lefts {
      margin-left: 0px;
  }
  .filter-btn {
      padding: 9px 31px!important;
  }
    /* moviesession */
    .b-bread-movies ul li {
      margin-right: 35px !important;
  }
  .movie-details {
      margin-top: 25px;
  }
  .movie-trailer {
      margin-top: 25px;
  }
  .d-flex.movies-forms {
      justify-content: space-between;
      width: 100%;
  }
  .movies-forms .p-dropdown {
      width: 100%;
  }
  .mt-2.now-right {
      width: 24%;
  }
  .box-slot-moviesession {
      width: 23.28%!important;
  }
  .eng {
      bottom: 37px!important;
  }
  .now-showing-only-desktop .mt-2.now-right {
    width: auto;
  }
    /* moviesession */
    /* booking flow */
    .select-date {
      width: 27% !important;
  }
  .select-date .p-dropdown .p-dropdown-trigger{
    width: 1rem;
  }
  .select-date .p-dropdown .p-dropdown-label{
    font-size: 11px;
  }
   
  .btn-proceeded{
  padding: 12px !important;
  }
   
   
  .cvp-quanity {
      flex-wrap: wrap;
  }
   
  .apply-cvp {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
  }
   
  .summary-movies-photo img {
      height: 106px;
      overflow: hidden;
      width: auto;
  }
   
  .summary-movies-photo {
      text-align: center;
      margin-bottom: 10px;
  }
   
   
  .col-6.mobile-food-space {
      flex: 0 0 auto !important;
      width: 50% !important;
  }
   
  .d-flex.justify-content-between.my-1.align-items-center {
      flex-wrap: wrap;
  }
  .food-title-name {
      width: 45%;
  }
  .side-basket {
      width: 40% !important;
  }
  .title-food {
      width: 23%!important;
  }
  .movies-summary {
      flex-direction: column;
  }
  .summary-movies-photo {
      text-align: center;
      margin-bottom: 10px;
      margin-right: 0px !important;
  }
   
  .apply-coupon .col-12 {
  width:100% !important;
  }
  .company-strenght-photo img {
      max-width: 100%;
  }
  .col-3{
    width: 25% !important;
  }
  .drop-quanity::before{
    left: 129px !important;
  }
  .foods-details {
    width: 100% !important;
    margin-top: 20px;
  }
  .col-8{
    width: 66.66666667% !important;
  }
  .food-all{
    width: 100% !important;
  }
  .food-popup {
  
    flex-wrap: wrap;
  }
  .foods-details h5 {
    margin-bottom: 20px !important;
  }
  .summary-movies-photo img {
    height: auto !important;
    overflow: hidden; 
    width: 100% !important;
  }
  .payment-method ul li {
    font-size: 11px;
  }
  .news-details{
    width: 95%;
  }
  .seven-year-highlight{
    overflow-x: scroll;
  }
  .highlight-table {
    width: 100% !important;
    overflow-x: scroll;
  }
  .highlight-table::-webkit-scrollbar-track{
    width: 10px !important;
  }
  .bluk-sub{
    padding: 9px 17px !important;
  }
  .trailer-m .mt-2.now-right{
    width:100%;
    }
    .kindly p {
      font-size: 8px !important;
    }
    .passport-user h3 {
      font-size: 11px !important;
    }
    /* booking flow */
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .seat-flow-custom-scroll {
      display: block !important;
    }
  .container, .container-sm {
      max-width: 94%;
  }
    .nav-center .p-inputtext.p-component {
      font-size: 13px;
    }
    .all-links {
      display: none !important;
    }
    .nav-center {
      font-size: 12px;
    }
    .nav-center .p-dropdown .p-dropdown-trigger {
      width: 28px;
    }
    .nav-center .p-dropdown.p-component.p-inputwrapper {
      font-size: 13px;
    }
    .logos-cinema {
      width: 100%;
    }
    .filter-btn {
      padding: 11px 10px !important;
    }
    .movie-slides {
      padding: 28px 93px !important;
    }
    .now-movie {
      grid-gap: 17px;
    }
    .now-movies {
      width: 29% !important;
  }
    .book-tickets-btn {
      padding: 7px 16px !important;
      font-size: 13px;
      border-radius: 4px;
    }
    .view-details {
      font-size: 11px;
      white-space: pre;
      padding: 3px 4px !important;
    }
    .bell-plus img {
      width: 84%;
    }
    .bell-plus {
      text-align: center;
    }
    .experiences-img img {
      margin-bottom: 20px;
    }
    .quick-book p {
      font-size: 15px;
    }
    .desktop-view {
      display: none !important;
    }
    .tablet-view {
      display: block !important;
    }
    .tablet-view .p-button {
      border: none;
      background: none;
      color: #000;
      font-size: 16px;
    }
    .tablet-view .pi {
      font-size: 24px;
    }
    .tablet-view .p-button:enabled:hover,
    .p-button:not(button):not(a):not(.p-disabled):hover {
      background: none;
      border: none;
      color: #000;
    }
    .quick-booking-from {
      flex-wrap: wrap;
    }
    .quick-left {
      margin-right: 10px;
      margin-left: 0px !important;
      display: inherit !important;
      margin-bottom: 8px;
      width: inherit !important;
    }
    .date-show {
      margin-bottom: 8px;
      margin-right: 10px;
    }
    .redmee-coupon h6 {
      font-size: 11px;
      margin: 0;
      padding-right: 3px;
    }
    .vail-date {
      font-size: 10px;
    }
    .view-details-btn {
      font-size: 10px;
      padding: 7px 7px !important;
    }
    .all-btn {
      margin-top: 15px;
    }
    .media {
      display: none;
    }
    .media-tabview {
      display: block;
      padding-top: 6px;
    }
    .media-tabview ul li {
      float: left;
      color: grey;
      list-style: none;
      font-size: 21px;
      margin-right: 21px;
    }
    .redmee-coupon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .movie-details h1 {
      font-size: 32px !important;
    }
    .action ul li a {
      font-size: 14px;
    }
    .tabview-menu {
      display: flex;
      align-items: center;
      padding: 7px 4px;
  }
  .more-content{
    height: 200px !important;
  }
    .tab-view-menu ul li {
      list-style: none;
      color: #000;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      width: 100%;
    }
    .tablet-view .p-sidebar .p-sidebar-content {
      padding: 0px 0px 0px 0px !important;
    }
    .tab-view-menu .p-sidebar .p-sidebar-content {
      padding: 0 !important;
    }
    .movie-name h1 {
      font-size: 25px !important;
    }
    .movie-icon {
      text-align: right !important;
    }
    .copy-right {
      padding-top: 8px;
      font-size: 14px;
    }
    .col-4 {
      flex: 0 0 auto;
      width: 33.33333333% !important;
    }
    .tab-view-menu .p-dropdown.p-invalid.p-component {
      border-color: none !important;
    }
    .tab-view-menu .p-dropdown {
      background: none;
      border: none;
    }
    .tab-view-menu .p-dropdown .p-dropdown-label.p-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #000;
      padding: 0;
    }
    .tab-view-menu .p-dropdown:not(.p-disabled).p-focus {
      box-shadow: none;
    }
    .icon-direction p {
      font-size: 11px;
    }
    .showtimes-cinemas-name p {
      font-size: 12px !important;
    }
    .direaction-maps span {
      margin-right: 10px !important;
      font-size: 14px;
    }
    .eng {
      bottom: 31px !important;
    }
    .banner-indicator-active {
      position: relative;
      left: -27px;
  }
   
  .date-show {
      width: 42%;
  }
   
  .cinemas-active, .cinemas-inactive {
      padding: 4.5px 37.25px !important;
  }
   
  .quick-lefts {
      margin-left: 0px;
  }
  .filter-btn {
      padding: 9px 31px!important;
  }
    /* moviesession */
    .b-bread-movies ul li {
      margin-right: 35px !important;
  }
  .movie-details {
      margin-top: 25px;
  }
  .movie-trailer {
      margin-top: 25px;
  }
  .d-flex.movies-forms {
      justify-content: space-between;
      width: 100%;
  }
  .movies-forms .p-dropdown {
      width: 100%;
  }
  .mt-2.now-right {
      width: 24%;
  }
  .box-slot-moviesession {
      width: 23.28%!important;
  }
  .eng {
      bottom: 37px!important;
  }
  .now-showing-only-desktop .mt-2.now-right {
    width: auto;
  }
    /* moviesession */
    /* booking flow */
    .select-date {
      width: 27% !important;
  }
  .select-date .p-dropdown .p-dropdown-trigger{
    width: 1rem;
  }
  .select-date .p-dropdown .p-dropdown-label{
    font-size: 11px;
  }
   
  .btn-proceeded{
  padding: 12px !important;
  }
   
   
  .cvp-quanity {
      flex-wrap: wrap;
  }
   
  .apply-cvp {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 20px;
  }
   
  .summary-movies-photo img {
      height: 106px;
      overflow: hidden;
      width: auto;
  }
   
  .summary-movies-photo {
      text-align: center;
      margin-bottom: 10px;
  }
   
   
  .col-6.mobile-food-space {
      flex: 0 0 auto !important;
      width: 50% !important;
  }
   
  .d-flex.justify-content-between.my-1.align-items-center {
      flex-wrap: wrap;
  }
  .food-title-name {
      width: 45%;
  }
  .side-basket {
      width: 40% !important;
  }
  .title-food {
      width: 23%!important;
  }
  .movies-summary {
      flex-direction: column;
  }
  .summary-movies-photo {
      text-align: center;
      margin-bottom: 10px;
      margin-right: 0px !important;
  }
   
  .apply-coupon .col-12 {
  width:100% !important;
  }
  .company-strenght-photo img {
      max-width: 100%;
  }
  .col-3{
    width: 25% !important;
  }
  .drop-quanity::before{
    left: 129px !important;
  }
  .foods-details {
    width: 100% !important;
    margin-top: 20px;
  }
  .col-8{
    width: 66.66666667% !important;
  }
  .food-all{
    width: 100% !important;
  }
  .food-popup {
  
    flex-wrap: wrap;
  }
  .foods-details h5 {
    margin-bottom: 20px !important;
  }
  .summary-movies-photo img {
    height: auto !important;
    overflow: hidden; 
    width: 100% !important;
  }
  .payment-method ul li {
    font-size: 11px;
  }
  .news-details{
    width: 95%;
  }
  .seven-year-highlight{
    overflow-x: scroll;
  }
  .highlight-table {
    width: 100% !important;
    overflow-x: scroll;
  }
  .highlight-table::-webkit-scrollbar-track{
    width: 10px !important;
  }
  .bluk-sub{
    padding: 9px 17px !important;
  }
  .trailer-m .mt-2.now-right{
    width:100%;
    }
  }
  
  